.lth-c-numberinput {
	/*------------------------------------------------------- */

	--numberinput-caret-color: var(--color-neutral-600);
	--numberinput-caret-focus-bg-color: var(--color-neutral-200);

	--numberinput-caret-active-color: var(--color-neutral-800);
	--numberinput-caret-active-bg-color: var(--color-neutral-200);

	--numberinput-caret-focus-shadow: 0 0 2px var(--color-primary-500);
	--numberinput-caret-disabled-color: var(--color-neutral-400);

	--numberinput-caret-focus-border-width: var(--border-width-s);
	--numberinput-caret-focus-border-color: var(--color-primary-600);

	/*------------------------------------------------------- */
}
