.lth-c-datepicker {
	/*------------------------------------------------------- */

	--datepicker-icon-color: var(--color-neutral-600);
	--datepicker-inline-space: var(--space-inline-s);

	--datepicker-sublabel-font-size: var(--typography-body-xsmall-size);
	--datepicker-sublabel-font-weight: var(--typography-font-weight-strong);
	--datepicker-sublabel-lineheight: var(--typography-body-xsmall-lineheight);

	--datepicker-sublabel-stack-space: var(--space-stack-xs);
	--datepicker-label-sublabel-stack-space: var(--space-stack-s);

	/*------------------------------------------------------- */
}
