.lth-c-dropdown {
	/*------------------------------------------------------- */

	--dropdown-border-color: var(--control-border-color);
	--dropdown-text-color: var(--color-neutral-800);

	--dropdown-menu-margin-top: var(--space-stack-xs);
	--dropdown-menu-bg-color: var(--color-neutral-400);
	--dropdown-menu-font-size: var(--typography-body-regular-size);

	--dropdown-menu-item-padding: var(--space-inset-squish-narrow-m);

	--dropdown-menu-item-hover-bg-color: var(--color-neutral-100);
	--dropdown-menu-item-focus-border-width: var(--control-focus-border-width);
	--dropdown-menu-item-focus-border-color: var(--control-focus-border-color);
	--dropdown-menu-item-focus-shadow: var(--control-focus-shadow);
	--dropdown-menu-item-active-bg-color: var(--color-neutral-300);
	--dropdown-menu-zindex: var(--zindex-control);

	--dropdown-button-placeholder-text-color: var(--color-gray-40);
	--dropdown-button-value-text-color: var(--control-border-color);
	--dropdown-button-font-family: var(--typography-font-family-base);
	--dropdown-button-font-size: var(--typography-body-regular-size);
	--dropdown-button-lineheight: var(--typography-body-regular-lineheight);
	--dropdown-button-border-width: var(--border-width-s);
	--dropdown-button-border-color: var(--color-neutral-500);
	--dropdown-button-border-radius: var(--border-radius-s);
	--dropdown-button-spacing-inset: var(--space-inset-s);
	--dropdown-button-arrow-color: var(--color-neutral-800);

	--dropdown-button-bg-color: var(--color-neutral-100);
	--dropdown-hover-button-bg-color: var(--color-neutral-200);
	--dropdown-active-button-bg-color: var(--color-neutral-300);

	--dropdown-button-focus-shadow: var(--control-focus-shadow);
	--dropdown-button-focus-border-width: var(--control-focus-border-width);
	--dropdown-button-focus-border-color: var(--control-focus-border-color);

	--dropdown-button-disabled-text-color: var(--color-neutral-400);
	--dropdown-button-disabled-border-color: var(--color-neutral-400);
	--dropdown-button-disabled-chevron-color: var(--color-neutral-400);

	--dropdown-button-slim-spacing-inset: var(--space-stack-xs) var(--space-stack-s);

	/*------------------------------------------------------- */
}
