.lth-c-link {
	/*------------------------------------------------------- */

	--link-text-color: var(--color-primary-600);
	--link-disabled-text-color: var(--color-neutral-400);
	--link-active-text-color: var(--color-primary-800);
	--link-monochrome-text-color: var(--color-neutral-800);
	--link-focus-border-radius: var(--border-radius-s);
	--link-visited-text-color: var(--color-info-visited);

	--link-focus: var(--control-focus);

	/*------------------------------------------------------- */
}
