.lth-c-colorpicker {
	/*------------------------------------------------------- */

	--colorpicker-picker-zindex: var(--zindex-control);
	--colorpicker-font-family: var(--typography-font-family-base);

	--colorpicker-button-lineheight: var(--typography-body-regular-lineheight);
	--colorpicker-button-border-width: var(--border-width-s);
	--colorpicker-button-border-color: var(--color-neutral-400);
	--colorpicker-button-border-radius: var(--border-radius-s);
	--colorpicker-button-arrow-color: var(--color-neutral-800);
	--colorpicker-button-focus-border-width: var(--control-focus-border-width);
	--colorpicker-button-focus-border-color: var(--control-focus-shadow-color);
	--colorpicker-button-shadow: var(--control-focus-shadow);
	--colorpicker-button-hover-border-color: var(--color-neutral-600);

	--colorpicker-text-color: var(--color-neutral-500);
	--colorpicker-text-zindex: var(--zindex-control);

	--colorpicker-icon-border-color: var(--color-neutral-400);
	
	--colorpicker-empty-line-color: var(--color-error-100);

	--colorpicker-button-chevron-color: var(--color-neutral-500);
	--colorpicker-button-hover-chevron-color: var(--color-neutral-800);

	--colorpicker-disabled-button-chevron-color: var(--color-neutral-400);
	--colorpicker-disabled-text-color: var(--color-neutral-300);


	--colorpicker-button-spacing-v-inset--small: var(--space-stack-xs);
	--colorpicker-button-spacing-h-inset--small: var(--space-inline-s);

	--colorpicker-button-spacing-v-inset--normal: var(--space-stack-s);
	--colorpicker-button-spacing-h-inset--normal: var(--space-inline-s);

	/*------------------------------------------------------- */
}
