@import './tokens';

/*-------------------------------------------------------*/

.lth-c-pretitle {
	font-family: var(--typography-font-family-base);
	font-weight: var(--typography-pretitle-font-weight);
	display: inline-block;
	color: var(--pretitle-color);
	text-transform: uppercase;

	font-size: var(--typography-pretitle-size);
	line-height: var(--typography-pretitle-lineheight);
	letter-spacing: var(--typography-pretitle-letterspacing);
}

/*-------------------------------------------------------*/
