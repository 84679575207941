@import './tokens';

/*-------------------------------------------------------*/

.lth-c-caption {
	font-family: var(--typography-font-family-base);
	font-weight: var(--typography-caption-font-weight);
	display: inline-block;
	color: var(--caption-color);
	text-transform: var(--caption-text-transform);

	font-size: var(--typography-caption-size);
	line-height: var(--typography-caption-lineheight);
	letter-spacing: var(--typography-caption-letterspacing);
}

/*-------------------------------------------------------*/
