.lth-u-scroll {
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}
	
	&::-webkit-scrollbar-track-piece {
		background-color: rgba(0,0,0,0.04);
		opacity: 0;
		width: 6px;
		transition: opacity 1s;
	}
	
	&:hover::-webkit-scrollbar-track-piece {
		background-color: rgba(0,0,0,0.06);
		opacity: 1;
		transition: opacity 1s;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: rgba(0,0,0,0);
	}
	
	&:hover::-webkit-scrollbar-thumb {
		background-color: rgba(0,0,0,0.3);
		border: 1px solid rgba(255,255,255,0.4);
	}
	
	&::-webkit-scrollbar-thumb:hover{
		background-color: rgba(0,0,0,0.5);
		border: 1px solid rgba(255,255,255,0.5);
	}
}

.lth-is-fullwidth {
	display: block;
	width: 100%;
	box-sizing: border-box;
}