@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

:root {
	/*------------------------------------------------------- */

	--typography-font-family-base: Roboto;
	--typography-font-family-heading: Roboto;
	--typography-font-family-monospace: Roboto Mono;

	/*------------------------------------------------------- */

	--typography-letterspacing-neg-1: -1px;
	--typography-letterspacing-normal: 0px;
	--typography-letterspacing-pos-1: 0.2px;
	--typography-letterspacing-pos-2: 0.4px;

	/*------------------------------------------------------- */

	--typography-font-weight-normal: 400;
	--typography-font-weight-strong: 500;
	--typography-font-weight-bold: 700;

	/*------------------------------------------------------- */

	--typography-heading1-size: 44px;
	--typography-heading1-lineheight: 64px;
	--typography-heading1-weight: normal;

	--typography-heading2-size: 34px;
	--typography-heading2-lineheight: 48px;
	--typography-heading2-weight: normal;

	--typography-heading3-size: 28px;
	--typography-heading3-weight: bold;
	--typography-heading3-lineheight: 40px;

	--typography-heading4-size: 24px;
	--typography-heading4-weight: normal;
	--typography-heading4-lineheight: 36px;

	--typography-heading5-size: 19px;
	--typography-heading5-weight: normal;
	--typography-heading5-lineheight: 28px;

	--typography-heading6-size: 16px;
	--typography-heading6-weight: bold;
	--typography-heading6-lineheight: 24px;

	/*------------------------------------------------------- */

	--typography-body-large-size: 20px;
	--typography-body-large-lineheight: 32px;

	--typography-body-regular-size: 16px;
	--typography-body-regular-lineheight: 24px;

	--typography-body-small-size: 14px;
	--typography-body-small-lineheight: 24px;

	--typography-body-xsmall-size: 12px;
	--typography-body-xsmall-lineheight: 16px;

	--typography-caption-size: 12px;
	--typography-caption-lineheight: 16px;
	--typography-caption-letterspacing: var(--typography-letterspacing-pos-1);
	--typography-caption-font-weight: var(--typography-font-weight-strong);

	--typography-pretitle-size: 12px;
	--typography-pretitle-lineheight: 16px;
	--typography-pretitle-letterspacing: var(--typography-letterspacing-pos-2);
	--typography-pretitle-font-weight: var(--typography-font-weight-bold);
}
