@import "./tokens";

/*-------------------------------------------------------*/

.lth-c-datatable {
	@mixin cell {
		padding-top: var(--datatable-cell-padding-v);
		padding-bottom: var(--datatable-cell-padding-v);
		// border-bottom: 1px solid var(--color-neutral-400);
	}
	
	width: 100%;

	&__table {
		width: 100%;
		border-spacing: 0;
		border-collapse: separate;
		text-align: left;


		&__heading {
			padding-bottom: var(--space-stack-xs);
			color: var(--color-neutral-600);
		}
	
		&__value {
			@include cell;
			font-size: 14px;
			max-width: 0;
			vertical-align: middle;
			white-space: nowrap;
			
			&:not(:last-child) {
				padding-right: var(--space-inline-m);
			}
			
			& > .lth-c-text {
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 100%;
			}

			&--wraparound {
				white-space: normal;
				vertical-align: top;
			}
		}
	
		&__row:first-child > &__value {
			border-top: 2px solid var(--color-neutral-200);
		}
	
		&__row:last-child > &__value {
			padding-bottom: 16px;
			border-bottom: 2px solid var(--color-neutral-200);
		}
	}

	&__navigator {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: var(--space-stack-l);

		&__index {
			margin-left: var(--space-inline-m);
			margin-right: var(--space-inline-m);
		}
	}
}

/*-------------------------------------------------------*/
