@import './tokens';

/*-------------------------------------------------------*/

.lth-c-radiobutton {
	color: var(--radiobutton-text-color);
	font-family: var(--radiobutton-font-family);
	font-size: var(--radiobutton-label-font-size);
	line-height: var(--radiobutton-label-line-height);
	outline: none !important;

	/*----*/

	&--inline {
		display: inline-block;
	}

	/*----*/

	& > label {
		display: block;
		white-space: nowrap;
	}

	&__control {
		display: inline-block;
		position: relative;
		vertical-align: top;
		padding: 3px;
		line-height: 0;
		margin-top: var(--space-stack-xxs);
		user-select: none;
	}

	&__control &__circle{
		display: inline-block;
		position: relative;
		width: var(--radiobutton-control-size);
		height: var(--radiobutton-control-size);
		border: var(--radiobutton-control-border-width) solid var(--radiobutton-control-border-color);
		// box-shadow: inset 0 0 0 var(--radiobutton-control-border-width) var(--radiobutton-control-border-color);
		border-radius: var(--border-radius-circle);
	}

	/*----*/

	// can change to plain bg, no need for psuedo code
	&:not(.lth-is-disabled).lth-is-hover &__circle:before, 
	&:not(.lth-is-disabled):hover &__circle:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: inherit;
		background: var(--radiobutton-hover-control-bg-color);
		z-index: var(--zindex-behind);
	}

	/*----*/

	&.lth-is-focus &__circle, &:focus &__circle {
		box-shadow: var(--radiobutton-focus-border), var(--radiobutton-focus-shadow);
	}
	
	/*----*/

	&__control &__dot {
		display: none;
		background: var(--radiobutton-control-dot-color);
		border-radius: 100px;
		position: absolute;
		width: var(--radiobutton-control-dot-size);
		height: var(--radiobutton-control-dot-size);
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	&__input {
		display: none;
	}

	&__input:checked+&__control &__dot {
		display: block;
	}

	&__reveal {
		display: none;
		margin-top: var(--space-stack-m);
	}

	&__input:checked + &__control + &__label &__reveal {
		display: block;
	}

	/*----*/

	&__label {
		display: inline-block;
		white-space: initial;
		margin-left: var(--radiobutton-control-inline-space);
	}

	/*----*/

	&.lth-has-error &__circle {
		border-color: var(--radiobutton-error-control-color);
	}

	&.lth-has-error &__input:checked+&__control &__dot {
		background: var(--radiobutton-error-control-color);
	}

	/*----*/

	&.lth-is-disabled {
		color: var(--radiobutton-disabled-text-color);
	}
	
	&.lth-is-disabled &__circle {
		border-color: var(--radiobutton-disabled-control-border-color);
	}

	&.lth-is-disabled &__dot {
		background: var(--radiobutton-disabled-control-dot-color);
	}

	&.lth-is-disabled &__input:checked+&__control &__dot {
	}
}

/*-------------------------------------------------------*/
