@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

.lth-c-select {
	$baseClass: &;
	font-size: 0;
	width: max-content;

	&__container {
		position: relative;
		border-radius: var(--select-input-border-radius);
		font-size: var(--typography-body-regular-size);
		line-height: var(--typography-body-regular-lineheight);
		font-family: var(--typography-font-family-base);
		overflow: hidden;

		#{$baseClass}__icon {
			position: absolute;
			right: var(--select-input-inset-hspacing);
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
		}
		
		&:before {
			@include lth-absolute-fullsize;
			content: "";
			
			border: var(--select-input-border-width) solid var(--select-input-border-color);
			border-radius: inherit;
			pointer-events: none;
		}
	}

	&.lth-has-error {
		#{$baseClass}__container:before {
			border-color: var(--select-input-error-border-color);
		}
		
		#{$baseClass}__container:hover:before {
			border-color: var(--select-input-error-hover-border-color);
		}
	}

	&__input {
		color: var(--select-input-text-color);
		background-color: var(--select-input-bg-color);
		font: inherit;
		user-select: none;
		white-space: nowrap;
		
		outline: none;
		box-sizing: border-box;
		
		padding: var(--select-input-inset-spacing);
		@at-root #{$baseClass}--small & {
			padding: var(--select-input-inset-spacing--small);
		}
		@at-root #{$baseClass}--large & {
			padding: var(--select-input-inset-spacing--large);
		}

		padding-right: calc(var(--space-inline-s) + var(--space-inline-s) + 20px) !important;
		// Above is not ideal - I'd rather space out with css grid,
		// but that's a bit tricky given all the different states (select/native/combo).
		// So this was a quick'n'dirty solution until a better one is built.
		
		&__placeholder {
			color: var(--select-input-placeholder-text-color);
			pointer-events: none;
			font: inherit;

			.lth-is-disabled & {
				color: var(--select-input-disabled-placeholder-text-color);
			}
		}

		&:hover:before {
			border-color: var(--select-input-hover-border-color);
		}

		@at-root select#{&} {
			appearance: none;
			width: 100%;
			border: none;
		}

		@at-root select#{&} + #{$baseClass}__input__placeholder {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: var(--select-input-inset-hspacing);
		}

		.lth-is-disabled & {
			pointer-events: none;
		}
	}

	&.lth-is-focus &__container:before,
	&__container:focus:before,
	&__container:focus-within:before {
		border: var(--select-focus-border-width) solid var(--select-focus-border-color);
		box-shadow: var(--select-focus-shadow);
	}
}

/*-------------------------------------------------------*/
