:root {
	/*------------------------------------------------------- */

	--space-stack-xxs:	2px;
	--space-stack-xs:		4px;
	--space-stack-s:		8px;
	--space-stack-m:		16px;
	--space-stack-l:		24px;
	--space-stack-xl:		32px;
	--space-stack-xxl:	64px;

	/*------------------------------------------------------- */

	--space-inline-xxs: 	2px;
	--space-inline-xs: 	4px;
	--space-inline-s: 	8px;
	--space-inline-m: 	16px;
	--space-inline-l: 	32px;
	--space-inline-xl: 	48px;
	--space-inline-xxl: 	64px;

	/*------------------------------------------------------- */

	--space-inset-xs:		4px 4px;
	--space-inset-s:		8px 8px;
	--space-inset-m:		16px 16px;
	--space-inset-l:		32px 32px;
	--space-inset-xl:		64px 64px;

	/*------------------------------------------------------- */

	--space-xs: 2px;
	--space-s: 4px;
	--space-m: 8px;
	--space-l: 16px;
	--space-xl: 32px;
	--space-xxl: 64px;

	/*------------------------------------------------------- */

	--space-inset-squish-s: 4px 24px;
	--space-inset-squish-m: 8px 24px;
	--space-inset-squish-l: 12px 24px;

	--space-inset-squish-narrow-s: 4px 16px;
	--space-inset-squish-narrow-m: 8px 16px;
	--space-inset-squish-narrow-l: 12px 16px;

	/*------------------------------------------------------- */

	--space-inset-stretch-s: 12px 4px;
	--space-inset-stretch-m: 12px 8px;
	--space-inset-stretch-l: 12px 12px;

	--space-inset-stretch-xl: 16px 32px;

	/*------------------------------------------------------- */
	/*------------------------------------------------------- */
}