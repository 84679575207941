@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

.lth-c-overflowmenu__container {
	display: inline-block;
	position: relative;
	// user-select: none;

	&.lth-is-fullwidth {
		display: block;
	}

	.lth-is-disabled & {
		pointer-events: none;
	}
}

.lth-c-overflowmenu {
	$baseClass: &;
	$maxHeight: 350px; /* should be configurable somehow */

	display: inline-block;

	position: absolute;
	font-size: var(--overflowmenu-font-size);
	color: var(--dropdown-text-color);
	background: var(--overflowmenu-bg-color);
	max-height: $maxHeight;
	box-sizing: border-box;
	text-align: left;
	margin-top: var(--overflowmenu-margin-top);
	z-index: var(--overflowmenu-zindex);
	overflow: hidden;
	transition: max-height 0.11s cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 0.01s cubic-bezier(0.2, 0, 0.38, 0.9);

	border-radius: var(--border-radius-s);
	box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);

	&:not(.lth-is-open) {
		max-height: 0;
		box-shadow: 0px 0px 0 0 rgba(0, 0, 0, 0);
		overflow: hidden;
		transition: max-height 0.11s cubic-bezier(0.2, 0, 0.38, 0.9),
			box-shadow 0.01s 0.1s cubic-bezier(0.2, 0, 0.38, 0.9);
	}

	/*---*/

	&__scrollable {
		overflow-y: auto;
		max-height: $maxHeight;
	}

	/*---*/

	&__item {
		color: var(--color-gray-20);
		white-space: nowrap;
		border-radius: var(--border-radius-s);
		position: relative;
		cursor: default;
		
		&:not(&--no-padding) {
			padding: var(--overflowmenu-item-padding);
		}
		
		&__icon {
			margin-top: 4px;
			margin-right: var(--overflowmenu-item-icon-spacing-inline);
		}

		&:hover {
			background: var(--overflowmenu-item-hover-bg-color);
		}

		&:focus {
			outline: none;

			&:before {
				@include lth-absolute-fullsize;

				content: "";
				border-radius: inherit;
				border: var(--overflowmenu-item-focus-border-width) solid var(--overflowmenu-item-focus-border-color);
				box-shadow: var(--overflowmenu-item-focus-shadow);
			}
		}

		&:active {
			background: var(--overflowmenu-item-active-bg-color);
		}

		&.lth-is-selected {
			background: var(--overflowmenu-item-selected-bg-color);

			&:hover {
				background: var(--overflowmenu-item-selected-hover-bg-color);
			}
		}

		&.lth-is-disabled {
			pointer-events: none;
		}
	}

	/*---*/

	@at-root &--slim {
		#{$baseClass}__item {
			padding: var(--overflowmenu-slim-item-padding);
		}
	}
	/*---*/

	&__separator {
		position: relative;
		height: var(--overflowmenu-separator-height);
	}

	&__separator:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: calc(50% - 1px);
		height: 0;
		border-bottom: var(--overflowmenu-separator-border-width) solid var(--overflowmenu-separator-border-color);
	}

	/*---*/

	&.lth-is-right-aligned {
		right: 0;
		left: auto;
	}
}

/*-------------------------------------------------------*/
