@import './tokens';

/*-------------------------------------------------------*/

@mixin sizeStyle($size) {
	&--#{$size} {
		@extend .lth-c-text;
		
		font-size: var(--typography-body-#{$size}-size);
		line-height: var(--typography-body-#{$size}-lineheight);
	}
}

/*-------------------------------------------------------*/

.lth-c-text {
	font-family: var(--typography-font-family-base);
	font-weight: normal;
	display: inline-block;
	color: var(--text-color);

	@include sizeStyle(xsmall);
	@include sizeStyle(small);
	@include sizeStyle(regular);
	@include sizeStyle(large);

	&--bold {
		font-weight: var(--text-bold-font-weight);
	}

	&.lth-is-disabled {
		color: var(--text-disabled-color);
	}
}

/*-------------------------------------------------------*/
