/*------------------------------------------------------- */

.lth-c-tabs {
	--tabs-button-text-color: var(--color-neutral-800);
	--tabs-button-font-weight: var(--typography-font-weight-normal);
	--tabs-button-border-width: var(--border-width-m);
	--tabs-button-border-color: var(--color-neutral-400);
	--tabs-button-border-radius: 0;
	--tabs-button-background-color: transparent;

	--tabs-button-selected-text-color: var(--color-primary-600);
	--tabs-button-selected-font-weight: var(--typography-font-weight-bold);
	--tabs-button-selected-border-width: var(--border-width-m);
	--tabs-button-selected-border-color: var(--color-primary-600);
	--tabs-button-selected-background-color: transparent;

	--tabs-button-hover-text-color: var(--color-primary-600);
	--tabs-button-active-text-color: var(--color-primary-800);

	--tabs-button-hover-background-color: var(--tabs-button-background-color);

	--tabs-button-focus-border-radius: var(--border-radius-s);
	--tabs-button-focus-border-color: var(--control-focus-border-color);
	--tabs-button-focus-border-width: var(--control-focus-border-width);

	--tabs-button-focus-shadow: var(--control-focus-shadow);

	--tabs-button-medium-regular-padding: var(--space-inset-squish-narrow-l);
	--tabs-button-medium-compact-padding: var(--space-inset-squish-narrow-m);
	--tabs-button-small-regular-padding: var(--space-inset-squish-narrow-m);
	--tabs-button-small-compact-padding: var(--space-inset-squish-narrow-s);

	--tabs-button-regular-fixed-width: 120px;
	--tabs-button-compact-fixed-width: 88px;

	--tabs-button-regular-font-size: var(--typography-body-regular-size);
	--tabs-button-medium-font-size: var(--typography-body-regular-size);
	--tabs-button-small-font-size: var(--typography-body-small-size);

	--tabs-button-margin-to-badge: var(--space-inline-s);

	--tabs-button-disabled-text-color: var(--color-neutral-400);
}

/*------------------------------------------------------- */
