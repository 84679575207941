@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

body#showcase {
	overflow: auto;
	padding-bottom: 8em;

	/*------------------------------------------------------- */

	--typography-font-family-heading: Lora;
	--typography-font-family-base: 'M PLUS Rounded 1c', sans-serif;

	--typography-heading1-size: 72px;
	--typography-heading1-lineheight: 90px;
	--typography-heading1-weight: 500;

	--typography-heading2-size: 62px;
	--typography-heading2-lineheight: 77.5px;
	--typography-heading2-weight: 500;

	--typography-heading3-size: 52px;
	--typography-heading3-lineheight: 65px;
	--typography-heading3-weight: 500;


	--typography-body-large-size: 25px;
	--typography-body-large-lineheight: 30px;

	--typography-body-regular-size: 21px;
	--typography-body-regular-lineheight: 25.2px;

	--typography-body-small-size: 18px;
	--typography-body-small-lineheight: 21.6px;

	--typography-body-xsmall-size: 16px;
	--typography-body-xsmall-lineheight: 19.2px;

	.lth-c-heading {
		--heading-color: var(--color-brand-black);
	}

	.lth-c-text {
		--text-color: var(--color-brand-black);
	}
	
	.lth-c-label {
		--label-text-font-weight: var(--typography-font-weight-strong);
		--label-text-color: var(--color-brand-black);
	}
	
	// COLORS
	--color-brand-blue: #2134E7;
	--color-brand-darkblue: #0014D5;
	--color-brand-black: #141415;
	--color-brand-grey: #323237;
	--color-brand-yellow: #F5B91E;
	--color-brand-green: #14D690;
	--color-brand-ash: #ECECEC;

	--color-light-blue: #F8F8FF;
	--color-light-grey: #F7F7F7;
	--color-light-yellow: #FFFBEE;
	--color-light-green: #F0FFF8;
	--color-light-white: #FFFFFF;
	--color-light-red: #FFF3F3;

	// BORDERS 
	--border-radius-xs: 4px;
	--border-radius-s: 8px;
	--border-radius-m: 12px;
	--border-radius-l: 16px;
	--border-radius-xl: 32px;

	// BUTTONS 
	--space-inset-squish-narrow-s: 8px 16px;
	--space-inset-squish-narrow-m: 12px 36px;
	--space-inset-squish-narrow-l: 20px 50px;

	--button-text-transform: uppercase;

	.lth-c-button {
		--button-large-label-font-size: var(--typography-body-regular-size);
		--button-medium-label-font-size: var(--typography-body-small-size);
		--button-small-label-font-size: var(--typography-body-xsmall-size);

		--button-primary-regular-text-color: var(--color-light-white);
		--button-primary-regular-bg-color: var(--color-brand-blue);
		--button-primary-hover-bg-color: var(--color-brand-darkblue);
		--button-primary-active-bg-color: var(--color-brand-darkblue);
		--button-primary-focus-bg-color: var(--color-brand-blue);
		--button-primary-focus-border-color: var(--color-brand-darkblue);
		
		--button-common-regular-text-color: var(--color-light-white);
		--button-common-regular-bg-color: var(--color-brand-grey);
		--button-common-hover-bg-color: var(--color-brand-black);
		--button-common-active-bg-color: var(--color-brand-black);
		--button-common-pressed-bg-color: var(--color-brand-black);

	}
	
	// TABS
	.lth-c-tabs {
		--tabs-button-medium-regular-padding: var(--space-inset-squish-narrow-s);
		--tabs-button-medium-compact-padding: var(--space-inset-squish-narrow-s);
		--tabs-button-small-regular-padding: var(--space-inset-squish-narrow-s);
		--tabs-button-small-compact-padding: var(--space-inset-squish-narrow-s);

		--tabs-button-regular-font-size: var(--typography-body-small-size);
		--tabs-button-border-width: 0;
		--tabs-button-border-radius: var(--border-radius-xl);
		--tabs-button-selected-background-color: var(--color-light-blue);
		--tabs-button-selected-text-color: var(--color-brand-blue);
		--tabs-button-active-text-color: var(--color-brand-blue);

		--tabs-button-text-color: var(--color-brand-grey);
		--tabs-button-hover-text-color: var(--tabs-button-text-color);
		--tabs-button-hover-background-color: var(--color-light-grey);

		&__buttons {
			border: 1px solid var(--color-brand-ash);
			border-left: none;
			border-right: none;
			padding: 20px 0;
		}
	}

	// THUMBNAILS
	.lth-c-thumbnails {
		--thumbnail-bg-color: transparent;
		--thumbnail-hover-bg-color: transparent;
		--thumbnail-active-bg-color: transparent;
		--thumbnail-selected-bg-color: var(--color-light-blue);
		--thumbnail-border-color: var(--color-light-ash);
		--thumbnail-hover-border-color: var(--thumbnail-border-color);
		--thumbnail-active-border-color: var(--thumbnail-border-color);
		--thumbnail-selected-border-color: var(--color-brand-blue);
		--thumbnail-label-margin: 12px;

		--thumbnail-border-color: transparent;
		--thumbnail-hover-border-color: transparent;
		--thumbnail-active-border-color: transparent;
		--thumbnail-selected-border-color: transparent;

		--thumbnail-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
		--thumbnail-hover-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1);
		--thumbnail-selected-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1);

		&__thumbnail {
			&__control {
				transition: box-shadow 0.2s;
			}
		}

		.lth-c-caption {
			--typography-caption-font-weight: 500;
			--typography-caption-size: 14px;
			--caption-text-transform: uppercase;

			--thumbnail-label-color: var(--color-brand-grey);
			--thumbnail-selected-label-color: var(--color-brand-blue);
		}
	}

	// RANGE SLIDER
	.lth-c-rangeslider {
		--rangeslider-fill-color: var(--color-brand-blue);
		--rangeslider-track-color: var(--color-brand-ash);
		--rangeslider-handle-bg-color: var(--color-brand-blue);
		--rangeslider-handle-border-color: var(--color-light-white);
		--rangeslider-handle-border-width: var(--border-width-l);
		--rangeslider-handle-hover-border-color: var(--rangeslider-handle-border-color);
		--rangeslider-handle-active-border-color: var(--rangeslider-handle-border-color);
		--rangeslider-handle-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);
	}

	.theme {
		&__section {
			margin-bottom: 64px;

			&__content {
				border-top: 1px solid gray;
				margin-top: 16px;
				padding-top: 16px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				&--buttons {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 32px;
				}
			}
		}

		.colors {
			&__section {
				display: flex;
				gap: 8px;
	
				margin-top: 16px;
				margin-bottom: 32px;
			}
	
			&__color {
				width: 64px;
				height: 64px;
				border-radius: var(--border-radius-l);
				box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
			}
		}
	}
}
