@import './tokens';

/*-------------------------------------------------------*/

.lth-c-daterangepicker {
	outline: none;

	&__container {
		display: grid;
		grid-template-columns: auto min-content auto;
		column-gap: var(--datepicker-inline-space);
		align-items: end;
	}

	&__separator {
		padding: var(--space-stack-s) 0;
		font-size: var(--typography-body-regular-size);
	}
}

/*-------------------------------------------------------*/
