.container {
	overflow-y: scroll;
	height: 100%;

	.publishButtons {
		margin-top: var(--space-stack-xxl);
	}
}

.rangeContainer {
	display: flex;
	align-items: center;

	span {
		margin-left: 1em;
		background-color: #e2e2e2;
		--size: 29px;
		width: var(--size);
		height: var(--size);
		display: grid;
		place-items: center;
		font-size: 1.2em;
	}
}
