@import './tokens';

/*-------------------------------------------------------*/

.lth-c-spinner {
	font-size: 0;
	line-height: 0;

	// fix this size of the spinner
	width: var(--size);
	height: var(--size);
	overflow: hidden;

	@keyframes rotate {
		0% {
			 transform: rotate(0deg)
		}

		to {
			 transform: rotate(1turn)
		}
  }

  & svg {
		animation-name: rotate;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
		width: var(--size);
		height: var(--size);
  }
}

/*-------------------------------------------------------*/
