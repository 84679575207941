@import "./tokens";
@import "../mixins";

.lth-c-checkbox {
	$baseClass: &;

	/*------- BASE --------*/

	color: var(--checkbox-text-color);
	font-family: var(--checkbox-font-family);
	outline: none !important;

	// fixing z-indexes
	// https://stackoverflow.com/a/9072467/390035
	position: relative;
	z-index: var(--zindex-base);

	@each $size in (medium, small) {
		&--#{$size} {
			font-size: var(--checkbox-label-font-size-#{$size});
			line-height: var(--checkbox-label-line-height-#{$size});
		}
	}

	label {
		display: flex;
		align-items: flex-start;
	}

	&__container {
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: var(--checkbox-control-size);
		height: var(--checkbox-control-size);
		margin-right: var(--checkbox-container-inline-space);
		outline-offset: -1px;
	}

	&__control {
		position: relative;
		display: inline-block;
		padding: 2px;
		margin-top: var(--space-stack-xxs);
		border-radius: var(--checkbox-control-border-radius);
		box-shadow: inset 0 0 0 var(--checkbox-control-border-width);
		color: var(--checkbox-control-border-color);
		background: var(--checkbox-control-bg-color);
		user-select: none;

		&__icon {
			display: none;
			fill: white;
		}
	}

	&__check {
		line-height: 0;
		visibility: hidden;

		path {
			fill: var(--checkbox-control-check-color);
		}
	}

	&__indeterminate {
		position: absolute;
		width: 6px;
		height: 2px;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		background: var(--checkbox-control-indeterminate-color);
	}

	&__label {
		display: inline-block;
	}

	input {
		display: none;
	}

	/*------- STATES --------*/

	&:hover:not(.lth-is-disabled) {
		#{$baseClass}__control {
			background: var(--checkbox-hover-control-bg-color);
		}
	}

	&:focus:not(.lth-is-disabled),
	&.lth-is-focus:not(.lth-is-disabled) {
		#{$baseClass}__control:before {
			content: "";
			@include lth-absolute-fullsize;
			border-radius: var(--checkbox-focus-control-border-radius);
			box-shadow: var(--checkbox-focus-control);
			background: var(--control-focus-border-color);
			z-index: var(--zindex-behind);
		}
	}

	&.lth-is-disabled {
		#{$baseClass}__label {
			color: var(--checkbox-disabled-label-color);
		}
		
		#{$baseClass}__control {
			color: var(--checkbox-disabled-control-border-color);
		}
		
		#{$baseClass}__indeterminate {
			background: var(--checkbox-disabled-indeterminate-color);
		}

		input:checked + #{$baseClass}__container {
			#{$baseClass}__control {
				color: var(--checkbox-disabled-control-border-color);
				background: var(--checkbox-disabled-control-bg-color);
			}
		}
	}

	&.lth-has-error {
		& input:checked + #{$baseClass}__container {
			#{$baseClass}__control {
				color: var(--checkbox-error-control-border-color);
				background: var(--checkbox-error-control-bg-color);
			}
		}

		&.lth-is-indeterminate {
			#{$baseClass}__control {
				color: var(--checkbox-error-control-border-color);
			}
			#{$baseClass}__indeterminate {
				background: var(--checkbox-error-indeterminate-color);
			}
		}
	}

	/*----------- VALUES ---------*/

	& input:checked + &__container {
		#{$baseClass}__control {
			color: var(--checkbox-control-checked-border-color);
			background: var(--checkbox-control-checked-bg-color);

			#{$baseClass}__check {
				visibility: visible;
			}
		}
	}

	&.lth-is-indeterminate {
		input:checked + #{$baseClass}__container {
			#{$baseClass}__check {
				visibility: hidden;
			}

			#{$baseClass}__control {
				background: var(--checkbox-control-bg-color);
			}
		}
	}
}
