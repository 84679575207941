@import './tokens';

/*-------------------------------------------------------*/

.lth-c-label {
	display: grid;
	grid-template-columns: auto min-content;
	align-items: center;
	font-family: var(--label-text-font-family);
	font-size: var(--label-text-font-size);
	font-weight: var(--label-text-font-weight);
	line-height: var(--label-text-lineheight);
	letter-spacing: 0.1px;
	color: var(--label-text-color);

	&--large {
		font-size: var(--label-text-large-font-size);
		line-height: var(--label-text-large-lineheight);
	}

	&--small {
		font-size: var(--label-text-small-font-size);
		line-height: var(--label-text-small-lineheight);
	}

	&:not(.lth-is-standalone) {
		margin-bottom: var(--space-stack-xxs);
	}
	
	&.lth-is-disabled, .lth-is-disabled & {
		color: var(--label-disabled-text-color);
	}
}

/*-------------------------------------------------------*/
