.lth-c-rating {
	/*------------------------------------------------------- */

	--rating-star-regular-color: var(--color-neutral-600);
	--rating-star-selected-color: var(--color-yellow-600);
	--rating-star-active-color: var(--color-yellow-700);
	--rating-star-hover-color: var(--color-yellow-600);
	--rating-star-disabled-color: var(--color-neutral-400);

	--rating-star-inline-space: var(--space-inline-xxs);
	--rating-star-focus: var(--control-focus);

	/*------------------------------------------------------- */
}
