@import './tokens';

/*-------------------------------------------------------*/

.lth-c-divider {
	font-family: var(--typography-font-family-base);
	font-size: var(--typography-body-regular-size);
	line-height: var(--typography-body-regular-lineheight);

	position: relative;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	height: var(--divider-height);
	position: relative;
	text-align: center;

	&__line {
		border-top: var(--divider-border-width) solid var(--divider-border-color);
		transform: translateY(50%);
	}

	&__label {
		position: relative;
		display: inline-block;
		background: inherit;
		padding: 0 var(--space-inline-l);
		z-index: 1;
		margin: auto;
		color: var(--divider-label-color);
	}
}

/*-------------------------------------------------------*/
