@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

.lth-l-grid {
	$baseClass: &;

	--lth-grid-column-gap: 32px;

	// allow centering the grid just by setting text-align=center on parent
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	display: inline-block;
	height: min-content;

	&:not(&--nomargin) {
		padding-left: calc(var(--lth-grid-column-gap) / 2);
		padding-right: calc(var(--lth-grid-column-gap) / 2);
	}

	&--fluid {
		display: block;
		width: 100%;
	}

	&--showGrid {
		background: rgba(255, 204, 204, 0.05);

		#{$baseClass}__container {
			background: rgba(255, 204, 204, 0.3);
			box-shadow: calc(var(--lth-grid-column-gap) / 2) 0 rgba(255, 204, 204, 0.3),
				calc(0px - var(--lth-grid-column-gap) / 2) 0 rgba(255, 204, 204, 0.3);
		}
	}

	&__container {
		display: inline-grid;
		position: relative;
		column-gap: var(--lth-grid-column-gap);
		grid-template-columns: repeat(var(--lth-grid-columns, 16), 64px);
		grid-template-rows: var(--lth-grid-rows, none);
		grid-auto-rows: min-content;
		align-items: start;
		margin-left: calc(var(--lth-grid-column-gap) / 2);
		margin-right: calc(var(--lth-grid-column-gap) / 2);
		height: 100%;

		#{$baseClass}--fluid & {
			display: grid;
			grid-template-columns: repeat(var(--lth-grid-columns, 16), 1fr);
		}

		#{$baseClass}--condensed & {
			column-gap: 2px;

			.stub {
				box-shadow: none !important;

				&:after {
					border: none !important;
				}
			}
		}

		#{$baseClass}--narrow & > * {
			margin-left: calc(
				-1 * var(--lth-grid-column-gap) / 2
			); // oddly, calc(0px - var(..)) was transformed to calc(var(..)) during build
		}

		& > * {
			xgrid-row: 1;
		}

		#{$baseClass}--showGrid & {
			pointer-events: none;
			z-index: var(--zindex-floating);

			#{$baseClass}__container {
				height: 100%;
			}

			.stub {
				background: rgba(255, 204, 204, 0.25);
				grid-row: 1;
				height: 100%;
				min-height: 150px;
				position: relative;

				&:not(:last-child):after {
					content: "";
					position: absolute;
					top: 0;
					right: calc(0px - var(--lth-grid-column-gap) / 2);
					bottom: 0;
					border-right: 1px solid rgba(255, 204, 204, 1);
				}
			}
		}
	}
}

/*-------------------------------------------------------*/
