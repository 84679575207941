@import "./tokens";
@import "../typography.scss";

/*-------------------------------------------------------*/

.lth-c-tabs {
	$BASE: &;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	overflow: hidden;

	&__buttons {
		position: relative;
		outline: none;
		display: flex;
		overflow-x: auto;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;

			// 1px extra negative spread to prevent artifacts on the left or right side
			box-shadow: inset 0 calc(-1px - var(--tabs-button-border-width)) 0 -1px var(--tabs-button-border-color);
		}

		&:is(.tlh-is-focus, :focus) .lth-is-selected:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: var(--tabs-button-focus-border-radius);
			border: var(--tabs-button-focus-border-width) solid var(--tabs-button-focus-border-color);
			box-shadow: var(--tabs-button-focus-shadow);
		}

		#{$BASE}__button-container {
			#{$BASE}__button {
				@extend .lth-body-regular;

				position: relative;
				border: none;
				outline: none;
				cursor: pointer;

				font-size: var(--button-font-size);
				line-height: var(--typography-body-regular-lineheight);
				font-weight: var(--tabs-button-font-weight);
				padding: var(--button-padding, var(--tabs-button-medium-regular-padding));
				color: var(--text-color);
				background-color: var(--tabs-button-background-color);
				border-radius: var(--tabs-button-border-radius);

				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				display: flex;
				align-items: center;

				&:is(.tlh-is-hover, :hover):not(.lth-is-disabled):not(&--selected) {
					color: var(--tabs-button-hover-text-color);
					background-color: var(--tabs-button-hover-background-color);
				}

				&:is(.tlh-is-active, :active) {
					color: var(--tabs-button-active-text-color);
				}

				--underline-color: var(--tabs-button-border-color);
				--text-color: var(--tabs-button-text-color);
				--font-weight: var(--tabs-button-font-weight);

				box-shadow: inset 0 calc(-1px - var(--tabs-button-border-width)) 0 -1px var(--underline-color);


				&--selected {
					--underline-color: var(--tabs-button-selected-border-color);
					--text-color: var(--tabs-button-selected-text-color);

					// make text appear bold, without changing the width of the parent, when set to content-fit
					--offset: 0.04ex;
					text-shadow: calc(-1 * var(--offset)) 0 currentColor, var(--offset) 0 currentColor;

					background-color: var(--tabs-button-selected-background-color);

					& > * {
						text-shadow: none;
					}
				}

				&.lth-is-disabled {
					--text-color: var(--tabs-button-disabled-text-color);
					cursor: default;
				}

				// when badge is shown - give it some space, and don't make it's text bolder.
				& > .lth-c-badge {
					margin-left: var(--tabs-button-margin-to-badge);
					transform: translateY(calc(-0.5 * var(--space-stack-xxs)));
				}
			}
		}
	}

	&__content {
		padding: 16px;
		flex: 1;
		overflow: auto;
	}

	--button-font-size: var(--tabs-button-regular-font-size);

	&--fixed &__buttons &__button-container &__button {
		width: var(--fixed-width);
	}

	&--regular {
		--button-padding: var(--button-padding-regular);
	}

	&--compact {
		--button-padding: var(--button-padding-compact);
	}

	&--medium {
		--button-font-size: var(--tabs-button-medium-font-size);
		--fixed-width: var(--tabs-button-regular-fixed-width);

		--button-padding-regular: var(--tabs-button-medium-regular-padding);
		--button-padding-compact: var(--tabs-button-medium-compact-padding);
	}

	&--small {
		--button-font-size: var(--tabs-button-small-font-size);
		--fixed-width: var(--tabs-button-compact-fixed-width);

		--button-padding-regular: var(--tabs-button-small-regular-padding);
		--button-padding-compact: var(--tabs-button-small-compact-padding);
	}
}

/*-------------------------------------------------------*/
