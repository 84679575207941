.lth-c-divider {
	/*------------------------------------------------------- */

	--divider-height: var(--typography-body-regular-lineheight);
	--divider-border-color: var(--color-neutral-400);
	--divider-border-width: var(--border-width-s);

	--divider-label-color: var(--color-neutral-600);

	/*------------------------------------------------------- */
}
