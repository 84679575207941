.container {
	height: var(--space-inline-xxl);

	grid-column: 1 / -1;
	grid-row: 1;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-bottom: var(--border-width-l) solid var(--color-neutral-400);
}
