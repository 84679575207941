// button/tab/dropdown font-size

:root {
	--control-focus-border-color: var(--color-primary-600);
	--control-focus-border-width: var(--border-width-m);

	--control-focus-shadow-color: var(--color-primary-500);
	--control-focus-shadow-blur: var(--shadow-blur-s);

	// --control-focus-border-color: 	red;
	// --control-focus-border-width: 	5px;

	// --control-focus-shadow-color: 	green;
	// --control-focus-shadow-blur: 		10px;

	--control-focus-shadow: 0 0 var(--control-focus-shadow-blur) 0 var(--control-focus-shadow-color);
	--control-focus-border: inset 0 0 0 var(--control-focus-border-width) var(--control-focus-border-color);
	--control-focus: var(--control-focus-border), var(--control-focus-shadow);

	--control-error-color: var(--color-error-200);
	--control-error-hover-color: var(--color-error-400);
	--control-spacing-inset: var(--space-inset-squish-narrow-m);

	--input-spacing-inset: var(--space-inline-s);
}
