@import './tokens';

/*-------------------------------------------------------*/

.lth-c-buttongroup {

	.lth-c-button:not(:active):not(.lth-is-pressed) + .lth-c-button:not(:first-child):not(:active):not(.lth-is-pressed):before {
		content: '';
		position: absolute;
		left: 0px;
		width: 1px;
		top: 50%;
		bottom: calc(50% - 24px);
		transform: translateY(-50%);
		background: var(--buttongroup-separator-border-color);
	}
}

/*-------------------------------------------------------*/
