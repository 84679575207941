@import './tokens';

/*-------------------------------------------------------*/

.lth-l-stack {
	.lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-m);
	}

	.lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-m);

		&.lth-spacing-dense {
			margin-top: var(--space-stack-xs) !important;
		}
	
		&.lth-spacing-compact {
			margin-top: var(--space-stack-s) !important;
		}
	
		&.lth-spacing-comfortable {
			margin-top: var(--space-stack-l) !important;
		}
	
		&.lth-spacing-spaced {
			margin-top: var(--space-stack-xl) !important;
		}
	}
	

	&.lth-spacing-dense > .lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-xs);
	}

	&.lth-spacing-compact > .lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-s);
	}

	&.lth-spacing-comfortable > .lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-l);
	}

	&.lth-spacing-spaced > .lth-l-stack__field:not(:first-child) {
		margin-top: var(--space-stack-xl);
	}
}

/*-------------------------------------------------------*/

.lth-l-stack-spacer {
	flex-shrink: 0;
}
