@import './tokens';

/*-------------------------------------------------------*/

.lth-c-tooltip__container {
	position: relative;
	font-size: 0;
	display: inline-flex;
}

.lth-c-tooltip {
	$baseTranslate: translateY(-50%) rotateZ(-45deg);
	
	position: absolute;
	transform: translateY(-50%);

	color: var(--tooltip-popover-text-color);
	background-color: var(--tooltip-popover-bg-color);
	border-radius: var(--tooltip-popover-border-radius);
	padding: 8px 12px;
	white-space: nowrap;

	z-index: var(--zindex-control);
	
	&:before {
		content: '';
		position: absolute;
		
		transform: $baseTranslate;
		background: inherit;
		width: 20px;
		height: 20px;
		z-index: var(--zindex-behind);
	}

	/*- left & right */
	&--right, &--left {
		top: 50%;
	
		&:before {
			top: 50%;
		}
	}

	&--right {
		left: calc(100% + 15px);

		&:before {
			left: -4px;
		}
	}

	&--left {
		right: calc(100% + 15px);

		&:before {
			right: -4px;
		}
	}

	/*- top & bottom */
	&--top, &--bottom {
		left: 50%;
		transform: translateX(-50%);
		
		&:before {
			left: 50%;
			transform-origin: 0 0;
			transform: $baseTranslate translateX(-100%);
		}
	}

	&--top {
		bottom: calc(100% + 15px);
		
		&:before {
			bottom: -10px;
		}
	}

	&--bottom {
		top: calc(100% + 15px);
		
		&:before {
			top: 2px;
		}
	}
}

/*-------------------------------------------------------*/
