@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

@mixin size($size) {
	&--#{$size} {
		padding: var(--button-#{$size}-padding);
		font-size: var(--button-#{$size}-label-font-size);
	}
}

@mixin style($variant) {
	&--#{$variant} {
		@extend .lth-c-button;
		color: var(--button-#{$variant}-regular-text-color);
		background: var(--button-#{$variant}-regular-bg-color);
		display: inline-grid;
		grid-template-columns: min-content auto min-content;
		align-items: center;
		text-decoration: none;

		&:before {
			content: "";
			@include lth-absolute-fullsize;
			border-radius: inherit;
			border: var(--button-#{$variant}-border-width) solid var(--button-#{$variant}-border-color);
		}

		&.lth-is-monochrome {
			color: var(--button-#{$variant}-monochrome-text-color, (--button-monochrome-text-color));

			&:before {
				border-color: var(--button-#{$variant}-monochrome-border-color, var(--button-monochrome-border-color));
			}
		}

		&:is(.lth-is-hover, :hover) {
			color: var(--button-#{$variant}-hover-text-color, var(--button-#{$variant}-regular-text-color));
			background: var(--button-#{$variant}-hover-bg-color);
		}

		&:is(.lth-is-active, :active) {
			background: var(--button-#{$variant}-active-bg-color);
		}

		&:is(.lth-is-focus, :focus):not(.lth-is-disabled) {
			&:before {
				border: var(--button-#{$variant}-focus-border-width) solid var(--button-#{$variant}-focus-border-color);
				box-shadow: var(--button-focus-shadow);
			}
		}

		&.lth-is-pressed {
			background-color: var(--button-#{$variant}-pressed-bg-color);
		}

		&.lth-is-disabled {
			pointer-events: none;
			color: var(--button-#{$variant}-disabled-text-color);
			background: var(--button-#{$variant}-disabled-bg-color);

			&:before {
				border-color: var(--button-#{$variant}-disabled-border-color);
			}
		}

		&.lth-has-icon {
			text-align: left;
		}
	}
}

/*-------------------------------------------------------*/

.lth-c-button {
	$baseClass: &;

	display: inline-block;
	position: relative;
	border: none;
	border-radius: var(--button-border-radius);
	line-height: var(--typography-body-regular-lineheight);
	font-family: var(--typography-font-family-base);
	text-transform: var(--button-text-transform);
	white-space: nowrap;
	cursor: pointer;

	&:not(.lth-is-ingroup) {
		// margin-right: var(--space-inline-s);
	}

	&.lth-is-spaced {
		margin-right: var(--space-inline-m);
	}

	&:focus {
		outline: none;
	}

	/*-----*/

	@include size(small);
	@include size(medium);
	@include size(large);

	&--medium {
		&.lth-is-icononly {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	&--small {
		&.lth-is-icononly {
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	&--large {
		&.lth-is-icononly {
			padding-left: 14px;
			padding-right: 14px;
		}
	}

	/*-----*/

	&__label {
		display: inline-block;
	}

	/*-----*/

	&:not(.lth-is-icononly) &__icon {
		display: inline-block;
		transform: translateY(-7%);
	}

	&__label + &__icon,
	&__icon + &__label {
		margin-left: var(--button-icon-margin);
	}

	// &__icon:first-child {
	// 	margin-left: calc(0px - 10px - var(--space-inline-xxs));
	// }

	/*-----*/

	@include style(primary);
	@include style(common);
	@include style(flat);
	@include style(outline);
	@include style(inverse);
	@include style(danger);
	@include style(warning);

	/*-----*/

	&--text {
		padding-left: var(--space-inline-s);
		padding-right: var(--space-inline-s);
	}

	/*-----*/

	&.lth-is-loading {
		pointer-events: none;

		.lth-c-spinner {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}

		& > *:not(.lth-c-spinner) {
			visibility: hidden;
		}
	}

	/*-----*/

	&.lth-is-groupFirst {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&.lth-is-groupLast {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&.lth-is-groupInner {
		border-radius: 0;
	}

	&.lth-is-fullwidth {
		display: grid;
	}

	&:not(.lth-is-fullwidth):not(.lth-is-ingroup) + &:not(.lth-is-fullwidth) {
		margin-left: var(--space-inline-s);
	}
}

/*-------------------------------------------------------*/
