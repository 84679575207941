:root {

	/*------------------------------------------------------- */

	--zindex-behind:		-1;
	--zindex-base:			0;
	--zindex-control:		10;
	--zindex-floating: 	100;
	--zindex-modals:		1000;
	--zindex-dialogs:		10000;
	--zindex-popups:		100000;
	--zindex-overflow:	1000000;

	/*------------------------------------------------------- */
}