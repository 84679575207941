@import "./tokens";

/*-------------------------------------------------------*/

.lth-c-link {
	display: inline-block;
	position: relative;
	text-decoration: none;
	color: var(--link-text-color);
	outline: none;
	cursor: pointer;

	&--inline,
	&.lth-is-hover,
	&:hover {
		&:before {
			position: absolute;
			content: "";
			bottom: 4px;
			left: 0;
			right: 0;
			border-bottom: thin solid;
		}
	}

	&--monochrome {
		.lth-c-text {
			color: var(--link-monochrome-text-color) !important;
		}

		&:before {
			border-color: var(--link-monochrome-text-color) !important;
		}
	}

	/*----*/

	&.lth-is-disabled {
		pointer-events: none;

		&:before {
			border-color: var(--link-disabled-text-color) !important;
		}
	}

	/*----*/

	&:is(.lth-is-active, :active) {
		color: var(--link-active-text-color);

		&:before {
			border-color: var(--link-active-text-color) !important;
		}
	}

	/*----*/

	&:is(.lth-is-focus, :focus):after {
		content: "";
		position: absolute;
		top: calc(0px - var(--space-xs));
		right: calc(0px - var(--space-s));
		bottom: calc(0px - var(--space-xs));
		left: calc(0px - var(--space-s));
		border-radius: var(--link-focus-border-radius);
		box-shadow: var(--link-focus);
	}

	&:visited {
		color: var(--link-visited-text-color);
		.lth-c-text {
			color: var(--link-visited-text-color);
		}
	}
}

/*-------------------------------------------------------*/
