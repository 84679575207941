@import './tokens';

/*-------------------------------------------------------*/

.lth-c-rangeslider {
	outline: none;

	&__control {
		display: grid;
		grid-template-columns: auto min-content;
		align-items: center;
	}

	@mixin barStyle {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		height: var(--rangeslider-track-width);
		border-radius: var(--rangeslider-track-radius);
	}

	/*-----*/

	&__track {
		position: relative;
		min-width: 150px;
		height: 32px;

		&:before {
			@include barStyle;

			content: '';
			background: var(--rangeslider-track-color);
		}
	}

	/*-----*/

	&__fill-container {
		height: inherit;
		position: relative;
	}

	&__fill {
		@include barStyle;
		background: var(--rangeslider-fill-color);
	}

	/*-----*/

	&__handle {
		position: absolute;
		right: 0;
		top: 50%;
		width: 12px;
		height: 12px;
		box-sizing: content-box;
		transform: translateX(50%) translateY(-50%);
		background-color: var(--rangeslider-handle-bg-color);
		border-radius: 100px;
		border: var(--rangeslider-handle-border-width) solid var(--rangeslider-handle-border-color);
		box-shadow: var(--rangeslider-handle-shadow);
		
		&:hover {
			border-color: var(--rangeslider-handle-hover-border-color);
		}
		
		&:active {
			border-color: var(--rangeslider-handle-active-border-color);
		}
	}
	
	&:focus &__handle, &.lth-is-focus &__handle {
		box-shadow: var(--rangeslider-handle-focus-shadow);
	}

	/*-----*/

	&__value {
		&--text {
			width: 32px;
			margin-left: var(--space-inline-m);
		}
		
		&--text-input {
			width: 48px;
			margin-left: var(--space-inline-m);
		}
		
		&--number-input {
			width: 64px;
			margin-left: var(--space-inline-m);
		}
	}

	/*-----*/

	&.lth-is-disabled &__control {
		pointer-events: none;
	}
	
	&.lth-is-disabled &__track:before {
		background: var(--rangeslider-disabled-track-color);
	}

	&.lth-is-disabled &__fill {
		background: var(--rangeslider-disabled-fill-color);
	}

	&.lth-is-disabled &__handle {
		border-color: var(--rangeslider-disabled-handle-border-color);
	}
}

/*-------------------------------------------------------*/
