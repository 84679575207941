/*------------------------------------------------------- */

:root {
	--mediaquery-minwidth-desktop: 768px;
	
	--mediaquery-maxwidth-tablet: 767px;
	--mediaquery-minwidth-tablet: 480px;
	
	--mediaquery-maxwidth-mobile: 479px;
	
	--mediaquery-desktop: screen and (min-width: var(--mediaquery-minwidth-desktop));
	--mediaquery-tablet: screen and (min-width: var(--mediaquery-minwidth-tablet) and (max-width: var(--mediaquery-maxwidth-tablet)));
	--mediaquery-mobile: screen and (max-width: var(--mediaquery-maxwidth-tablet));
}

/*------------------------------------------------------- */

