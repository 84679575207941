.container {
	overflow-y: hidden;

	grid-column: 4 / -1;
	grid-row: 2;
	height: 100%;
	height: 99%;

	display: flex;
	flex-direction: column;

	.buttons {
		color: blue;
	}

	.tabs {
		color: green;
	}
}
