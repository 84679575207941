.lth-c-checkbox {
	/*------------------------------------------------------- */

	--checkbox-font-family: var(--typography-font-family-base);
	--checkbox-text-color: var(--color-neutral-800);

	--checkbox-label-font-size-medium: var(--typography-body-regular-size);
	--checkbox-label-font-size-small: var(--typography-body-small-size);
	--checkbox-label-line-height-medium: var(--typography-body-regular-lineheight);
	--checkbox-label-line-height-small: var(--typography-body-small-lineheight);

	--checkbox-container-inset-space: var(--space-inset-xs);
	--checkbox-container-inline-space: var(--space-inline-xs);

	--checkbox-control-size: 20px;
	--checkbox-control-border-radius: var(--border-radius-xs);
	--checkbox-control-border-width: var(--border-width-s);
	--checkbox-control-border-color: var(--color-neutral-800);
	--checkbox-control-bg-color: var(--color-neutral-50);
	--checkbox-control-checked-bg-color: var(--color-neutral-800);
	--checkbox-control-checked-border-color: var(--color-neutral-800);
	--checkbox-control-check-color: var(--color-neutral-50);
	--checkbox-control-indeterminate-color: var(--color-neutral-800);

	--checkbox-disabled-control-bg-color: var(--color-neutral-400);
	--checkbox-disabled-control-border-color: var(--color-neutral-400);
	--checkbox-disabled-check-color: var(--color-neutral-50);
	--checkbox-disabled-label-color: var(--color-neutral-400);
	--checkbox-disabled-indeterminate-control-background: var(--color-neutral-50);
	--checkbox-disabled-indeterminate-control-border: var(--color-neutral-400);
	--checkbox-disabled-indeterminate-color: var(--color-neutral-400);

	--checkbox-focus-control-border-radius: var(--border-radius-xs);
	--checkbox-focus-border-width: var(--border-width-s);

	// extra 1px to cover the checkbox border
	--checkbox-focus-control-shadow: 0 0 var(--control-focus-shadow-blur) var(--checkbox-focus-border-width)
		var(--control-focus-shadow-color);
	--checkbox-focus-control-border: 0 0 0 var(--checkbox-focus-border-width) var(--control-focus-border-color);
	--checkbox-focus-control: var(--checkbox-focus-control-border), var(--checkbox-focus-control-shadow);

	--checkbox-error-text-color: var(--control-error-color);
	--checkbox-error-control-border-color: var(--color-error-200);
	--checkbox-error-control-bg-color: var(--color-error-200);
	--checkbox-error-indeterminate-color: var(--color-error-200);

	--checkbox-hover-control-bg-color: var(--color-neutral-200);

	/*------------------------------------------------------- */
}
