body#playground {
	// required to test the affect of scrolling on components
	height: 200vh !important;
	overflow: auto !important;

	#root {
		height: 100%;
	}
}

.playground {
	display: grid;
	grid-template-columns: min-content auto min-content;
	grid-template-rows: minmax(50%, auto) auto;
	border: 1px solid #888;
	height: 100vh;
	box-sizing: border-box;

	.sidebar {
		grid-row: 1/3;
		width: 300px;
		background: #f5f5f5;
		border-right: 1px solid #ddd;
		height: 100%;
		box-sizing: border-box;
		overflow-y: auto;
		padding: 24px;
		
		.modifier {
			margin-bottom: 12px;
		}
	}

	.canvas {
		grid-column: 2;
		grid-row: 1;
		position: relative;
		overflow: auto;

		.scroller {
			// height: 1000px;

			.example {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				min-width: 250px;
			}
		}
	}

	.code {
		grid-column: 2;
		grid-row: 2;
		overflow: hidden;
	}
}

.directory {
	width: 960px;
	margin: auto;
	text-align: center;

	.link {
		display: inline-block;
		margin: 12px;
	}
}