@import './tokens';

/*-------------------------------------------------------*/

.lth-c-datepicker {
	&__container {
	}

	&__sublabel {
		font-family: var(--typography-font-family-base);
		font-size: var(--datepicker-sublabel-font-size);
		font-weight: var(--datepicker-sublabel-font-weight);
		line-height: var(--datepicker-sublabel-lineheight);

		&:not(:empty) {
			margin-top: var(--datepicker-label-sublabel-stack-space);
		}
	}

	&__date {
		.rdtPicker {
			font-size: var(--typography-body-regular-size);
			font-family: var(--typography-font-family-base);
		}
	}

	&.lth-open-upwards {
		.rdtPicker {
			bottom: 100%;
		}
	}
}

/*-------------------------------------------------------*/
