@import '../tokens';

:root {

	/*------------------------------------------------------- */

	--toast-border-radius: var(--border-radius-s);
	--toast-text-color: var(--color-neutral-50);
	--toast-inset-spacing: var(--space-inset-m);
	--toast-inner-spacing: var(--space-inline-m);

	--toast-default-background-color: var(--color-neutral-600);
	--toast-success-background-color: var(--color-success-200);
	--toast-error-background-color: var(--color-error-200);

	/*------------------------------------------------------- */
}