@import '../mixins';
@import './tokens';

/*-------------------------------------------------------*/

.lth-c-thumbnails {
	@mixin size($size) {
		&--#{$size} &__container {
			grid-gap: var(--thumbnail-#{$size}-gap);
		}

		&--#{$size} &__thumbnail &__thumbnail__control__image {
			width: var(--thumbnail-#{$size}-size);
			height: var(--thumbnail-#{$size}-size);
		}

		&--#{$size} &__thumbnail__control {
			padding: var(--thumbnail-#{$size}-padding);
		}
	}

	$baseClass: &;
	--num-of-columns: 3;

	// .lth-c-label {
	// 	margin-bottom: var(--space-stack-m);
	// }
	
	& > label {
		display: block;
	}

	&__container {
		display: inline-grid;
		grid-template-columns: repeat(var(--num-of-columns),min-content);
	}

	&__thumbnail {
		$thumbnailClass: &;
		$controlClass: #{$thumbnailClass}__control;

		position: relative;
		
		& input {
			display: none;
		}

		#{$controlClass} {
			display: flex;
			flex-direction: column;
			align-items: center;
			box-sizing: border-box;
			width: 100%;
			height: 100%;

			font-size: 0;
			background-color: var(--thumbnail-bg-color);
			border-radius: var(--thumbnail-border-radius);
			position: relative;
			outline: var(--thumbnail-border-width) solid var(--thumbnail-border-color);
			text-align: center;
			box-shadow: var(--thumbnail-box-shadow);

			&__image {
				display: flex;
				align-items: center;
				text-align: center;

				& > * {
					margin: auto;
				}
			}

			.lth-c-caption {
				margin-top: var(--thumbnail-label-margin);
				transform: translateY(var(--space-stack-xs));
				color: var(--thumbnail-label-color);
			}
		}

		&__input:not(:checked) {
			& + #{$controlClass} {
				
				&:hover {
					background-color: var(--thumbnail-hover-bg-color);
					outline-color: var(--thumbnail-hover-border-color);
					box-shadow: var(--thumbnail-hover-box-shadow);
				}
				
				&:active {
					background-color: var(--thumbnail-active-bg-color);
					outline-color: var(--thumbnail-active-border-color);
				}
			}
		}

		&__input:checked {
			& + #{$controlClass} {
				background-color: var(--thumbnail-selected-bg-color);
				outline-color: var(--thumbnail-selected-border-color);
				box-shadow: var(--thumbnail-selected-box-shadow);

				& #{$controlClass}--normal {
					display: none;
				}
			
				& #{$controlClass}--selected {
					display: flex;
					flex: 1;
				}

				.lth-c-caption {
					color: var(--thumbnail-selected-label-color);
				}
			}

		}

		&__badge {
			@include lth-absolute-fullsize;
			overflow: hidden;
			pointer-events: none;

			&__stripe {
				position: absolute;
				right: 20px;
				top: 20px;
				transform: rotate(45deg) translateX(45%);
				z-index: var(--zindex-floating);
				transform-origin: center right;
				width: 100%;
				text-align: center;
				padding: 0;
				color: var(--color-neutral-white);
				background-color: #9060F0;
				font-weight: 300;
				letter-spacing: 0.5px;
				font-size: 12px;
				padding: 3px;
			}
		}
	}

	@include size(xsmall);
	@include size(small);
	@include size(medium);
	@include size(large);
	@include size(xlarge);
}

/*-------------------------------------------------------*/
