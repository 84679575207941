@import "./tokens";
@import "../utils.scss";

/*-------------------------------------------------------*/

.lth-c-codesnippet {
	position: relative;
	padding-right: 32px;
	background: var(--color-neutral-100);
	
	&__copybutton {
		font-size: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	& > pre {
		@extend .lth-u-scroll;
		border: none !important;
	}
}

/*-------------------------------------------------------*/
