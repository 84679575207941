.container {
	&:not(:last-of-type) {
		margin-bottom: var(--space-stack-xl);
	}

	& > div:first-child {
		margin: var(--space-stack-m) 0;
	}

	& > div:not(:first-child):not(:last-of-type) {
		margin-bottom: var(--space-stack-m);
	}
}
