.container {
	overflow-y: hidden;

	background-color: var(--color-neutral-50);

	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

	grid-column: 1 / 4;
	grid-row: 2;
	height: 100%;

	display: flex;
	flex-direction: column;
}
