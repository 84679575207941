.container {
	background-color: var(--color-neutral-50);

	display: flex;
	align-items: center;

	padding: var(--space-stack-s) var(--space-inline-m);

	& *:first-child {
		margin-right: var(--space-inline-s);
	}

	&:is(.selected, :hover) {
		background-color: var(--color-neutral-100);
	}
}
