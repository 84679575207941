/*------------------------------------------------------- */

:root {
	--border-radius-xs: 		1px;
	--border-radius-s: 		2px;
	--border-radius-m: 		4px;
	--border-radius-l: 		8px;
	--border-radius-circle: 1000px;

	--border-width-s: 		1px;
	--border-width-m: 		2px;
	--border-width-l: 		4px;
}

/*------------------------------------------------------- */
