@import './tokens';

/*-------------------------------------------------------*/

.lth-c-controlmessage {
	font-size: var(--controlmessage-font-size);
	font-weight: var(--controlmessage-font-weight);
	line-height: var(--controlmessage-lineheight);
	color: var(--controlmessage-text-color);
	letter-spacing: 0.1px;

	&.lth-has-error {
		color: var(--controlmessage-error-text-color);
	}

	&.lth-is-disabled {
		color: var(--controlmessage-disabled-text-color);
	}

	&:not(.lth-is-standalone) {
		margin-top: var(--controlmessage-spacing-top);
	}
}

/*-------------------------------------------------------*/
