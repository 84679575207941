.lth-c-label {
	--label-text-font-family: var(--typography-font-family-base);
	--label-text-font-size: var(--typography-body-regular-size);
	--label-text-font-weight: var(--typography-font-weight-normal);
	--label-text-lineheight: var(--typography-body-regular-lineheight);
	--label-text-color: var(--color-neutral-800);
	
	--label-text-small-font-size: var(--typography-body-small-size);
	--label-text-small-lineheight: var(--typography-body-small-lineheight);

	--label-text-large-font-size: var(--typography-body-large-size);
	--label-text-large-lineheight: var(--typography-body-large-lineheight);
	
	--label-disabled-text-color: var(--color-neutral-400);

	--label-tooltip-icon-color: var(--color-neutral-800);
	--label-disabled-tooltip-icon-color: var(--color-neutral-400);
}

/*------------------------------------------------------- */
