.lth-c-thumbnails {
	/*------------------------------------------------------- */

	--thumbnail-bg-color: var(--color-neutral-100);
	--thumbnail-hover-bg-color: var(--color-neutral-100);
	--thumbnail-active-bg-color: var(--color-neutral-100);
	--thumbnail-selected-bg-color: var(--color-neutral-100);

	--thumbnail-border-color: transparent;
	--thumbnail-border-width: var(--border-width-m);
	--thumbnail-border-radius: var(--border-radius-m);

	--thumbnail-hover-border-color: var(--color-neutral-200);
	--thumbnail-active-border-color: var(--color-neutral-400);
	--thumbnail-selected-border-color: var(--color-neutral-600);

	.lth-c-caption {
		--thumbnail-label-color: var(--caption-color);
		--thumbnail-selected-label-color: var(--thumbnail-label-color);
	}

	--thumbnail-box-shadow: none;
	--thumbnail-hover-box-shadow: none;
	--thumbnail-selected-box-shadow: none;

	--thumbnail-label-margin: var(--space-stack-xs);

	--thumbnail-xsmall-size: 32px;
	--thumbnail-xsmall-padding: 8px;
	--thumbnail-xsmall-gap: 8px;

	--thumbnail-small-size: 64px;
	--thumbnail-small-padding: 12px;
	--thumbnail-small-gap: 12px;

	--thumbnail-medium-size: 96px;
	--thumbnail-medium-padding: 16px;
	--thumbnail-medium-gap: 16px;

	--thumbnail-large-size: 128px;
	--thumbnail-large-padding: 20px;
	--thumbnail-large-gap: 20px;

	--thumbnail-xlarge-size: 160px;
	--thumbnail-xlarge-padding: 24px;
	--thumbnail-xlarge-gap: 24px;

	/*------------------------------------------------------- */
}
