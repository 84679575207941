@import './tokens';

/*-------------------------------------------------------*/

.lth-c-toaster {
	position: fixed;
	left: 50%;
	bottom: 32px;
	transform: translateX(-50%);

	&--position-left {
		left: 32px;
		transform: none;
	}

	&--position-right {
		left: auto;
		right: 32px;
		transform: none;
	}

	&__item {
		animation-fill-mode: forwards;
		overflow: hidden;
		
		&.animate-in {
			animation-duration: 1s;
			animation-name: appear;
		}
		
		&.animate-out {
			animation-duration: .5s;
			animation-name: disappear;
		}

		@keyframes appear {
			0% {
				max-height: 0;
			}

			100% {
				max-height: 200px;
			}
		}

		@keyframes disappear {
			0% {
				max-height: 200px;
				xopacity: 1;
			}

			100% {
				max-height: 0;
				xopacity: 0;
			}
		}
	}
}

/*-------------------------------------------------------*/

.lth-c-toast {
	display: inline-grid;
	grid-template-columns: min-content auto min-content;
	grid-column-gap: var(--toast-inner-spacing);
	color: var(--toast-text-color);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
	border-radius: var(--toast-border-radius);
	width: 320px;
	padding: var(--toast-inset-spacing);
	margin-top: 16px;

	
	&--default {
		background-color: var(--toast-default-background-color);
	}

	&--success {
		background-color: var(--toast-success-background-color);
	}

	&--error {
		background-color: var(--toast-error-background-color);
	}


	&__title, &__content {
		grid-column: 2;
		grid-row: 1;
	}

	&__title + &__content {
		grid-row: 2;
	}

	&__icon {
		align-self: top;
		margin-top: 1px;
	}

	&__close {
		grid-column: 3;
	}

	&.animate-out {
		opacity: 0;
	}
}

/*-------------------------------------------------------*/
