.lth-c-overflowmenu {
	/*------------------------------------------------------- */

	--overflowmenu-margin-top: var(--space-stack-xs);
	--overflowmenu-bg-color: var(--color-neutral-50);
	--overflowmenu-font-size: var(--typography-body-regular-size);

	--overflowmenu-item-padding: var(--space-inset-squish-narrow-m);
	--overflowmenu-slim-item-padding: var(--space-inset-squish-narrow-s);

	--overflowmenu-item-hover-bg-color: var(--color-neutral-100);
	--overflowmenu-item-focus-border-width: var(--control-focus-border-width);
	--overflowmenu-item-focus-border-color: var(--control-focus-border-color);
	--overflowmenu-item-focus-shadow: var(--control-focus-shadow);
	--overflowmenu-item-active-bg-color: var(--color-neutral-300);
	--overflowmenu-item-disabled-text-color: var(--color-neutral-400);

	--overflowmenu-item-selected-bg-color: var(--color-primary-100);
	--overflowmenu-item-selected-hover-bg-color: var(--color-primary-200);

	--overflowmenu-item-icon-spacing-inline: var(--space-inline-s);

	--overflowmenu-separator-height: 8px;
	--overflowmenu-separator-border-width: var(--border-width-m);
	--overflowmenu-separator-border-color: var(--color-neutral-200);

	--overflowmenu-zindex: var(--zindex-overflow);

	/*------------------------------------------------------- */
}
