:root {
	--color-neutral-white: #ffffff;
	--color-neutral-black: #000000;
	--color-transparent: transparent;

	//primary
	--color-primary-50: #f5f8ff;
	--color-primary-100: #e5edff;
	--color-primary-200: #ccdbff;
	--color-primary-300: #a8c2ff;
	--color-primary-400: #6c96f5;
	--color-primary-500: #346ceb;
	--color-primary-600: #0443db;
	--color-primary-700: #0033ad;
	--color-primary-800: #00298a;
	--color-primary-900: #001a57;

	// neutral
	--color-neutral-50: #fcfcfc;
	--color-neutral-100: #f0f4fa;
	--color-neutral-200: #e6eaf0;
	--color-neutral-300: #dde2eb;
	--color-neutral-400: #cfd6e0;
	--color-neutral-500: #a2abbd;
	--color-neutral-600: #6b768a;
	--color-neutral-700: #4f586b;
	--color-neutral-800: #343b4d;
	--color-neutral-900: #202433;

	// yellow
	--color-yellow-50: #fffcf5;
	--color-yellow-100: #fff6e3;
	--color-yellow-200: #ffeecc;
	--color-yellow-300: #ffe2a8;
	--color-yellow-400: #ffd175;
	--color-yellow-500: #ffc042;
	--color-yellow-600: #f5ab18;
	--color-yellow-700: #db9200;
	--color-yellow-800: #b87a00;
	--color-yellow-900: #946300;

	// cyan
	--color-cyan-50: #f5fdff;
	--color-cyan-100: #d9f6fc;
	--color-cyan-200: #bef0fa;
	--color-cyan-300: #99e8f7;
	--color-cyan-400: #66dcf4;
	--color-cyan-500: #30d0f0;
	--color-cyan-600: #0dbde0;
	--color-cyan-700: #0ca3c2;
	--color-cyan-800: #08748a;
	--color-cyan-900: #004c5c;

	// STATE

	// success
	--color-success-50: #fafffc;
	--color-success-100: #7acca1;
	--color-success-200: #03803d;
	--color-success-300: #025c2c;
	--color-success-400: #00421e;

	// error
	--color-error-50: #fff5f5;
	--color-error-100: #f09090;
	--color-error-200: #d20000;
	--color-error-300: #a80000;
	--color-error-400: #800000;

	// warning
	--color-warning-50: #fffbf5;
	--color-warning-100: #facf96;
	--color-warning-200: #fa9819;
	--color-warning-300: #e58200;
	--color-warning-400: #cc7400;

	// info
	--color-info-50: #f0f3fa;
	--color-info-100: #b5c8f5;
	--color-info-200: #0e4de5;
	--color-info-300: #0739b3;
	--color-info-400: #032b8a;
	--color-info-visited: #5f22e0;
}
