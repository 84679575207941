@import './tokens';

/*-------------------------------------------------------*/

.lth-c-socialbutton {
	display: inline-block;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
	font-size: 0;

	&.lth-is-fullwidth {
		display: block;
	}
}

/*-------------------------------------------------------*/
