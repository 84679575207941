@import "./tokens";
@import "../mixins";

/*------------------------------------------------------- */

.lth-c-dropdown {
	$baseClass: &;

	display: inline-block;
	position: relative;
	font-family: var(--typography-font-family-base);
	outline: none;

	&__button-container {
		cursor: pointer;
	}

	&__button {
		background: var(--dropdown-button-bg-color);
		font-family: var(--dropdown-button-font-family);
		font-size: var(--dropdown-button-font-size);
		line-height: var(--typography-body-regular-lineheight);
		border-radius: var(--dropdown-button-border-radius);
		padding: var(--dropdown-button-spacing-inset);
		border: none;
		outline: none;
		display: grid;
		grid-template-columns: auto min-content;
		align-items: center;
		column-gap: var(--space-inline-s);
		user-select: none;

		// border placeholder
		&:before {
			border-radius: inherit;
		}

		&__value {
			color: var(--dropdown-button-value-text-color);
		}

		&__placeholder {
			color: var(--dropdown-button-placeholder-text-color);
		}

		&:hover,
		#{$baseClass}.lth-is-hover & {
			background: var(--dropdown-hover-button-bg-color);
		}

		#{$baseClass}:focus &:before,
		#{$baseClass}.lth-is-focus &:before {
			@include lth-absolute-fullsize;

			content: "";
			border: var(--dropdown-button-focus-border-width) solid var(--dropdown-button-focus-border-color);
			box-shadow: var(--dropdown-button-focus-shadow);
		}

		&:active {
			background: var(--dropdown-active-button-bg-color);
		}

		#{$baseClass}--slim & {
			padding: var(--dropdown-button-slim-spacing-inset);
		}
	}

	&.lth-is-disabled,
	&.lth-is-disabled &__button__placeholder {
		color: var(--dropdown-button-disabled-text-color);
		pointer-events: none;
	}

	&.lth-is-disabled &__chevron {
		fill: var(--dropdown-button-disabled-text-color);
	}
}

/*------------------------------------------------------- */
