.lth-c-input {
	/*------------------------------------------------------- */

	--input-text-color: var(--control-border-color);

	--input-bg-color: var(--color-transparent);
	--input-placeholder-text-color: var(--color-neutral-500);
	--input-text-color: var(--color-neutral-800);
	--input-font-family: var(--typography-font-family-base);
	--input-font-size: var(--typography-body-regular-size);
	--input-lineheight: var(--typography-body-regular-lineheight);
	--input-border-width: var(--border-width-s);
	--input-border-color: var(--color-neutral-400);
	--input-border-radius: var(--border-radius-s);
	--input-small-spacing-inset: var(--space-inline-xs) var(--space-inline-s);
	--input-small-spacing-font-size: var(--typography-body-small-size);

	--input-icon-color: var(--color-neutral-600);

	--input-disabled-text-color: var(--color-neutral-400);
	--input-disabled-placeholder-text-color: var(--color-neutral-400);
	--input-disabled-border-color: var(--color-neutral-400);
	--input-disabled-icon-color: var(--color-neutral-400);
	
	--input-readonly-background-color: var(--color-neutral-200);

	--input-hover-border-width: var(--border-width-s);
	--input-hover-border-color: var(--color-neutral-600);

	--input-focus-border-width: var(--control-focus-border-width);
	--input-focus-border-color: var(--control-focus-shadow-color);

	--input-error-border-color: var(--control-error-color);
	--input-error-hover-border-color: var(--control-error-hover-color);
	--input-error-icon-color: var(--control-error-color);
	
	--input-focus-border: var(--control-focus-border-width) solid var(--control-focus-border-color);
	--input-focus-shadow: var(--control-focus-shadow);

	/*-------------------------------------------------------*/
}
