.lth-c-rangeslider {
	/*------------------------------------------------------- */

	--rangeslider-track-width: var(--border-width-l); // QUESTION
	--rangeslider-track-color: var(--color-neutral-400);
	--rangeslider-track-radius: var(--border-radius-s);

	--rangeslider-fill-color: var(--color-primary-600);
	
	--rangeslider-handle-bg-color: var(--color-neutral-400);
	--rangeslider-handle-border-color: var(--color-primary-600);
	--rangeslider-handle-border-width: var(--border-width-m);
	--rangeslider-handle-shadow: none;

	--rangeslider-handle-hover-border-color: var(--color-primary-700);
	--rangeslider-handle-active-border-color: var(--color-primary-700);
	--rangeslider-handle-active-border-color: var(--color-primary-700);
	--rangeslider-handle-focus-shadow: var(--control-focus-shadow);

	--rangeslider-disabled-track-color: var(--color-neutral-200);
	--rangeslider-disabled-fill-color: var(--color-neutral-400);
	--rangeslider-disabled-handle-border-color: var(--color-neutral-200);

	/*------------------------------------------------------- */
}
