/*------------------------------------------------------- */

.lth-c-button {
	--button-border-color: var(--color-neutral-800);
	--button-border-radius: var(--border-radius-s);
	--button-icon-margin: var(--space-inline-s);

	--button-primary-regular-text-color: var(--color-neutral-50);
	--button-primary-regular-bg-color: var(--color-primary-600);
	--button-primary-hover-bg-color: var(--color-primary-700);
	--button-primary-active-bg-color: var(--color-primary-800);
	--button-primary-focus-bg-color: var(--color-primary-600);
	--button-primary-focus-border-color: var(--color-primary-800);
	--button-primary-focus-border-width: var(--control-focus-border-width);
	--button-primary-disabled-text-color: var(--color-neutral-400);
	--button-primary-disabled-bg-color: var(--color-neutral-100);

	--button-common-regular-text-color: var(--color-neutral-800);
	--button-common-regular-bg-color: var(--color-neutral-100);
	--button-common-hover-bg-color: var(--color-neutral-200);
	--button-common-active-bg-color: var(--color-neutral-300);
	--button-common-pressed-bg-color: var(--color-neutral-300);
	--button-common-focus-border-color: var(--control-focus-border-color);
	--button-common-focus-border-width: var(--control-focus-border-width);
	--button-common-disabled-text-color: var(--color-neutral-400);
	--button-common-disabled-bg-color: var(--color-neutral-100);

	--button-flat-regular-text-color: var(--color-neutral-800);
	--button-flat-regular-bg-color: transparent;
	--button-flat-hover-bg-color: var(--color-neutral-100);
	--button-flat-active-bg-color: var(--color-neutral-200);
	--button-flat-focus-border-color: var(--control-focus-border-color);
	--button-flat-focus-border-width: var(--control-focus-border-width);
	--button-flat-disabled-text-color: var(--color-neutral-400);
	--button-flat-disabled-bg-color: transparent;
	--button-flat-icon-margin: var(--space-inline-s);

	--button-inverse-regular-text-color: var(--color-neutral-50);
	--button-inverse-border-color: transparent;
	--button-inverse-border-width: var(--border-width-m);
	--button-inverse-hover-text-color: var(--color-neutral-50);
	--button-inverse-hover-bg-color: var(--color-neutral-800);
	--button-inverse-active-text-color: var(--color-neutral-50);
	--button-inverse-active-bg-color: var(--color-neutral-700);
	--button-inverse-focus-bg-color: var(--color-neutral-400);
	--button-inverse-focus-border-color: var(--color-primary-600);
	--button-inverse-focus-border-width: var(--control-focus-border-width);
	--button-inverse-disabled-text-color: var(--color-neutral-600);
	--button-inverse-disabled-border-color: transparent;
	--button-inverse-disabled-bg-color: transparent;

	--button-outline-regular-text-color: var(--color-primary-600);
	--button-outline-border-color: var(--color-primary-600);
	--button-outline-border-width: var(--border-width-s);
	--button-outline-hover-bg-color: var(--color-neutral-100);
	--button-outline-active-bg-color: var(--color-neutral-200);
	--button-outline-focus-border-color: var(--control-focus-border-color);
	--button-outline-focus-border-width: var(--control-focus-border-width);
	--button-outline-disabled-text-color: var(--color-neutral-400);
	--button-outline-disabled-border-color: var(--color-neutral-400);
	--button-outline-disabled-bg-color: transparent;

	--button-danger-regular-text-color: var(--color-neutral-50);
	--button-danger-regular-bg-color: var(--color-error-200);
	--button-danger-hover-bg-color: var(--color-error-300);
	--button-danger-active-bg-color: var(--color-error-400);
	--button-danger-focus-border-color: var(--color-error-400);
	--button-danger-focus-border-width: var(--control-focus-border-width);
	--button-danger-disabled-text-color: var(--color-neutral-400);
	--button-danger-disabled-bg-color: var(--color-neutral-100);

	--button-warning-regular-text-color: var(--color-neutral-50);
	--button-warning-regular-bg-color: var(--color-warning-200);
	--button-warning-hover-bg-color: var(--color-warning-300);
	--button-warning-active-bg-color: var(--color-warning-400);
	--button-warning-focus-border-color: var(--color-warning-400);
	--button-warning-focus-border-width: var(--control-focus-border-width);
	--button-warning-disabled-text-color: var(--color-neutral-400);
	--button-warning-disabled-bg-color: var(--color-neutral-100);

	--button-focus-shadow: var(--control-focus-shadow);

	--button-monochrome-text-color: var(--color-neutral-800);
	--button-monochrome-border-color: var(--color-neutral-600);
	--button-monochrome-disabled-border-color: var(--color-neutral-400);

	--button-medium-padding: var(--space-inset-squish-narrow-m);
	--button-medium-label-font-size: var(--typography-body-regular-size);

	--button-small-padding: var(--space-inset-squish-narrow-s);
	--button-small-label-font-size: var(--typography-body-regular-size);

	--button-large-padding: var(--space-inset-squish-narrow-l);
	--button-large-label-font-size: var(--typography-body-regular-size);
}

/*------------------------------------------------------- */
