@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

.lth-c-input {
	$baseClass: &;

	/*----*/

	&__label-container {
		display: grid;
		grid-template-columns: auto min-content;
		align-items: center;

		#{$baseClass}__tooltip {
			grid-column: 2;
			grid-row: 1;
			margin-bottom: var(
				--space-stack-xxs
			); // need to solve this better - label now comes with a 2px spacing, which is not ideal.
			color: var(--input-tooltip-icon-color);
			margin-left: 8px;
		}
	}

	&__container {
		position: relative;
		outline: none;
		display: inline-grid;
		box-sizing: border-box;

		/* 1fr seems to help input expand to the entire available space.
			min-content for the right icon makes it invisible if overflow is used (like in NumberInput carets)
		*/
		grid-template-columns: min-content 1fr auto;

		align-items: center;
		background: var(--input-bg-color);
		border-radius: var(--input-border-radius);
		padding: var(--input-spacing-inset);
		font-size: var(--input-font-size);

		&:before {
			@include lth-absolute-fullsize;

			content: "";
			pointer-events: none;
			border-radius: inherit;
			border: var(--input-border-width) solid var(--input-border-color);
		}
	}

	&.lth-is-readonly &__container {
		background-color: var(--input-readonly-background-color);
	}

	&__control {
		position: relative; // make sure the __control is over border (via :before)
		display: inline-block;
		min-width: 0;
		width: 100%;
		padding: 0;
		box-sizing: border-box;
		border: none;
		outline: none;

		background: transparent;
		color: var(--input-text-color);
		font-family: var(--input-font-family);
		line-height: var(--typography-body-regular-lineheight);
		font-size: inherit;

		&::placeholder {
			color: var(--input-placeholder-text-color);
		}
	}

	&--small {
		#{$baseClass}__container {
			padding: var(--input-small-spacing-inset);
			font-size: var(--input-small-spacing-font-size);
		}
	}

	/*----*/

	&__icon {
		user-select: none;
	}

	&__icon + &__control,
	&__control + &__icon {
		padding-left: var(--space-inline-s);
	}

	&.lth-has-error &__icon {
		color: var(--input-error-icon-color);
	}

	/*----*/

	&.lth-is-disabled {
		pointer-events: none;
	}

	&.lth-is-disabled &__container:before {
		border: var(--input-border-width) solid var(--input-disabled-border-color);
	}

	&.lth-is-disabled &__control {
		color: var(--input-disabled-text-color);

		&::placeholder {
			color: var(--input-disabled-placeholder-text-color);
		}
	}

	&.lth-is-disabled &__icon {
		color: var(--input-disabled-icon-color);
	}

	&.lth-is-disabled &__tooltip {
		color: var(--input-disabled-tooltip-icon-color);
	}

	/*----*/

	&.lth-is-hover &__container:before,
	& &__container:hover:before {
		border: var(--input-hover-border-width) solid var(--input-hover-border-color);
	}

	&.lth-is-focus &__container:before,
	&__container:focus-within:before {
		border: var(--input-focus-border);
		box-shadow: var(--input-focus-shadow);
	}

	/*----*/

	&.lth-has-error &__container:before {
		border-color: var(--input-error-border-color);
	}

	&.lth-has-error &__container:hover:before {
		border-color: var(--input-error-hover-border-color);
	}

	/*----*/
}

/*-------------------------------------------------------*/
