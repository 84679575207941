@import './tokens';

/*-------------------------------------------------------*/

.lth-c-breadcrumbs {

	&__separator {
		display: inline-block;
		margin: 0 var(--space-inline-s);
	}
}

/*-------------------------------------------------------*/
