@import './tokens';

/*-------------------------------------------------------*/

.lth-c-rating {
	&__stars {
		outline: none;
		padding: var(--space-stack-xxs) 0;
		
		&.lth-has-keyboardfocus .lth-is-focus-target, &.lth-is-focus .lth-is-focus-target {
			box-shadow: var(--rating-star-focus);
		}
	}

	&__star {
		display: inline-block;
		user-select: none;

		&:active svg {
			fill: var(--rating-star-active-color) !important;
		}

		&+& {
			margin-left: var(--rating-star-inline-space);
		}
	}

	&.lth-is-disabled {
		pointer-events: none;
	}
}

/*-------------------------------------------------------*/
