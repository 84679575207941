.lth-c-radiobutton {
	/*------------------------------------------------------- */

	--radiobuttongroup-label-spacing: var(--group-label-spacing);

	--radiobutton-font-family: var(--typography-font-family-base);
	--radiobutton-text-color: var(--color-neutral-800);

	--radiobutton-label-font-size: var(--typography-body-regular-size);
	--radiobutton-label-line-height: var(--typography-body-regular-lineheight);

	--radiobutton-control-inline-space: var(--space-inline-xs);
	--radiobutton-control-size: 12px; // total size - 12px + 2px border = 14px
	--radiobutton-control-dot-size: 6px;
	--radiobutton-control-border-color: var(--color-neutral-800);
	--radiobutton-control-dot-color: var(--color-neutral-800);
	--radiobutton-control-border-width: var(--border-width-s);

	--radiobutton-disabled-text-color: var(--color-neutral-400);
	--radiobutton-disabled-control-border-color: var(--color-neutral-400);
	--radiobutton-disabled-control-dot-color: var(--color-neutral-400);

	--radiobutton-focus-shadow: 0 0 var(--control-focus-shadow-blur) var(--control-focus-border-width)
		var(--control-focus-shadow-color);
	--radiobutton-focus-border: 0 0 0 var(--control-focus-border-width) var(--control-focus-border-color);

	--radiobutton-message-spacing-top: var(--space-stack-xxs);

	--radiobutton-hover-control-bg-color: var(--color-neutral-200);

	/*------------------------------------------------------- */
}
