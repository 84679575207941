@import './tokens';

/*-------------------------------------------------------*/

.lth-c-icon {
	--lth-icon-color: currentColor;
	
	width: var(--lth-icon-size);
	min-width: var(--lth-icon-size);
	height: var(--lth-icon-size);
	vertical-align: top;
	fill: var(--lth-icon-color);

	&.lth-is-disabled {
		opacity: var(--lth-icon-disabled-opacity);
		pointer-events: none;
	}
}

/*-------------------------------------------------------*/
