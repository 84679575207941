@import "./tokens";
@import "../mixins";

/*-------------------------------------------------------*/

@mixin size($selector,$size) {
	@at-root #{$selector} {
		padding: var(--colorpicker-button-spacing-v-inset--#{$size}) var(--colorpicker-button-spacing-h-inset--#{$size});
	}
}

/*-------------------------------------------------------*/

.lth-c-colorpicker {
	$baseClass: &;

	outline: none;
	position: relative;
	font-family: var(--colorpicker-font-family);
	width: max-content;

	&__control {
		#{$baseClass}__button {
			background: var(--colorpicker-button-bg-color);
			line-height: var(--typography-body-regular-lineheight);
			padding: var(--colorpicker-button-spacing-h-inset) var(--colorpicker-button-spacing-v-inset);
			outline: none;
			display: grid;
			grid-template-columns: auto auto min-content;
			align-items: center;
			column-gap: var(--space-inline-s);
			// user-select: none;
			position: relative;
			height: 100%;
			box-sizing: border-box;

			#{$baseClass}__arrow {
				fill: var(--colorpicker-button-chevron-color);
				
			}
			
			&:hover #{$baseClass}__arrow {
				fill: var(--colorpicker-button-hover-chevron-color);
			}

			&:before {
				@include lth-absolute-fullsize;
				
				content: "";
				border: var(--colorpicker-button-border-width) solid var(--colorpicker-button-border-color);
				border-radius: var(--colorpicker-button-border-radius);
			}

			&:hover:before {
				border-color: var(--colorpicker-button-hover-border-color);
			}
			
			&:focus:before {
				border: var(--colorpicker-button-focus-border-width) solid var(--colorpicker-button-focus-border-color);
				box-shadow: var(--colorpicker-button-shadow);
			}

			@include size($baseClass &,normal); // default
			@include size(#{$baseClass}--small &,small);
		}
	

		#{$baseClass}__icon {
			display: inline-block;
			width: 32px;
			height: 16px;
			border-radius: var(--border-radius-s);
			border: 1px solid var(--colorpicker-icon-border-color);
			position: relative;
			overflow: hidden;

			&.lth-is-empty:before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				height: 0.5px;
				background: var(--colorpicker-empty-line-color);
				transform: rotateZ(-25deg) scaleX(2);
			}
		}

		#{$baseClass}__input {
			min-width: 120px;
		}

		#{$baseClass}__text {
			min-width: 6em;
			color: var(--colorpicker-text-color);
			z-index: var(--colorpicker-text-zindex);
		}
	}
	

	&__panel {
		width: 250px;
		user-select: none;
	}

	&__picker {
		background: white;
		box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		padding: 8px;

		&__saturation {
			height: 150px;
			position: relative;
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;
		}

		&__sliders_container {
			display: grid;
			grid-template-columns: 1fr min-content;
			grid-column-gap: 8px;
			margin-top: 8px;
		}

		&__slider {
			height: 8px;
			position: relative;

			& > div > div {
				border-radius: 100px;
			}

			& > div > div > div > div {
				width: 6px !important;
				height: 6px !important;
				border-radius: 100px !important;
				box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.6) !important;
				border: 1px solid white !important;
				background: transparent !important;
				margin: 0 !important;
			}

			&+& {
				margin-top: 8px;
			}
		}
		
		&__color_container {
			white-space: nowrap;
		}

		&__color {
			display: inline-block;
			width: 24px;
			height: 24px;
			border-radius: 2px;
			box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3) !important;
		}

		&__details {

			&__fields {
				padding-bottom: 12px;
				border-bottom: 1px solid rgb(238, 238, 238);
				margin-bottom: 10px;
			}

			&__clear {
				margin: 6px;
				cursor: pointer;
			}

			&__preset {
				margin-top: 8px;
			}
		}


	}

	&.lth-is-disabled {
		#{$baseClass}__button {
			pointer-events: none;
		}

		#{$baseClass}__text {
			color: var(--colorpicker-disabled-text-color);
		}

		#{$baseClass}__arrow {
			fill: var(--colorpicker-disabled-button-chevron-color);
		}

		#{$baseClass}__icon {
			opacity: 0.3;
			cursor: default;
			pointer-events: none;
		}
	}
}

/*-------------------------------------------------------*/
