@import './tokens';

/*-------------------------------------------------------*/

.lth-c-heading {

	@mixin style($variant) {
		&--#{$variant} {
			@extend .lth-c-heading;
			font-size: var(--typography-#{$variant}-size);
			font-weight: var(--typography-#{$variant}-weight);
			line-height: var(--typography-#{$variant}-lineheight);
		}
	}

	display: block;
	font-family: var(--typography-font-family-heading);
	color: var(--heading-color);

	@include style(heading1);
	@include style(heading2);
	@include style(heading3);
	@include style(heading4);
	@include style(heading5);
	@include style(heading6);
}

/*-------------------------------------------------------*/
