@import "./tokens";
@import "../mixins";
/*-------------------------------------------------------*/

.lth-c-toggle {
	$baseClass: &;

	outline: none !important;

	/*----*/

	--toggle-transition: 0.1s ease-in;
	$toggle-directions: (left right top);

	&__container {
		display: flex;
		flex-direction: var(--toggle-direction-left);
		justify-content: var(--toggle-justify-left);
		align-items: center;

		@include lth-parent-with-suffixes(
			$parent: $baseClass,
			$suffixes: $toggle-directions,
			$rule: flex-direction,
			$token: --toggle-direction
		);
		@include lth-parent-with-suffixes(
			$parent: $baseClass,
			$suffixes: $toggle-directions,
			$rule: justify-content,
			$token: --toggle-justify
		);
		@include lth-parent-with-suffixes(
			$parent: $baseClass,
			$suffixes: $toggle-directions,
			$rule: align-items,
			$token: --toggle-align
		);
	}

	&__track {
		position: relative;
		display: inline-block;
		vertical-align: top;
		line-height: 0;
		box-sizing: border-box;
		font-size: 0;
		width: var(--toggle-track-width);
		height: var(--toggle-track-height);
		border-radius: var(--border-radius-circle);
		background: var(--toggle-track-unchecked-bg-color);
		transition: background var(--toggle-transition);

		#{$baseClass}:focus &,
		#{$baseClass}.lth-is-focus & {
			box-shadow: 0px 0px 0px 2px var(--toggle-focus-check-border-color), 0px 0px 2px 2px var(--color-primary-500);
		}

		#{$baseClass}:active & {
			background: var(--toggle-track-active-bg-color) !important;
		}

		#{$baseClass}.lth-is-checked & {
			background: var(--toggle-track-checked-bg-color);
		}

		#{$baseClass}.lth-is-disabled & {
			background: var(--toggle-track-disabled-bg-color);
		}
	}

	/*----*/

	&__handle {
		position: absolute;
		top: 50%;
		left: 2px;
		transform: translateY(-50%);
		display: inline-block;
		vertical-align: top;
		line-height: 0;
		box-sizing: border-box;
		font-size: 0;
		width: var(--toggle-handle-size);
		height: var(--toggle-handle-size);
		border-radius: var(--border-radius-circle);
		background: white;
		transition: var(--toggle-transition);

		#{$baseClass}.lth-is-checked & {
			left: calc(100% - var(--toggle-handle-size) - 2px);
		}
	}

	/*----*/

	&__label {
		display: inline-block;

		margin: var(--toggle-label-margin-left);
		@include lth-parent-with-suffixes(
			$parent: $baseClass,
			$suffixes: $toggle-directions,
			$rule: margin,
			$token: --toggle-label-margin
		);

		& > * {
			transition: color var(--toggle-transition);
			#{$baseClass}.lth-is-disabled & {
				color: var(--toggle-disabled-text-color);
			}
		}

		@at-root #{$baseClass}.lth-is-fullwidth & {
			flex: 1;
		}
	}
}
