@import "../mixins";
@import "./tokens";

/*-------------------------------------------------------*/

.lth-c-numberinput {
	display: block;

	&__carets {
		display: inline-grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		padding-left: var(--space-inline-s);
		// position: absolute;
		// right: 8px;
		// top: 4px;
	}

	&__caret {
		position: relative;
		height: 12px;
		overflow-y: hidden;
		fill: var(--numberinput-caret-color);
		user-select: none;
		outline: none;
		display: flex;
		align-items: center;

		/* for future use - if we decide to make carets focusable, only via keyboard */
		.lth-has-keyboardfocus &:focus {
			box-shadow: var(--numberinput-caret-focus-shadow);
		}

		.lth-has-keyboardfocus &:focus:after {
			@include lth-absolute-fullsize;
			content: "";

			box-sizing: border-box;
			border: var(--numberinput-caret-focus-border-width) solid var(--numberinput-caret-focus-border-color);
		}
		/*--*/

		&:hover {
			background: var(--numberinput-caret-focus-bg-color);
		}

		&:active {
			fill: var(--numberinput-caret-active-color);
			background: var(--numberinput-caret-active-bg-color);
		}
	}

	&.lth-is-disabled &__caret {
		fill: var(--numberinput-caret-disabled-color);
	}
}

/*-------------------------------------------------------*/
