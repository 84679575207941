/*------------------------------------------------------- */

:root {
	--shadow-spread-s:		3px;
	--shadow-spread-m:		6px;
	--shadow-spread-l:		12px;

	--shadow-blur-s:			3px;
	--shadow-blur-m:			6px;
	--shadow-blur-l:			12px;
}

/*------------------------------------------------------- */
