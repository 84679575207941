.lth-c-controlmessage {
	--controlmessage-font-size: var(--typography-body-xsmall-size);
	--controlmessage-lineheight: var(--typography-body-xsmall-lineheight);
	--controlmessage-font-weight: var(--typography-font-weight-strong);
	--controlmessage-text-color: var(--color-neutral-800);
	--controlmessage-error-text-color: var(--control-error-color);
	--controlmessage-disabled-text-color: var(--color-neutral-400);
	--controlmessage-spacing-top: var(--space-stack-xs);
}

/*------------------------------------------------------- */
