.container {
	--margin: 0 0 0 var(--space-inline-m);
	margin: var(--margin);

	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.lower {
		padding-bottom: var(--space-stack-l);
	}

	.title {
		margin: var(--margin);
		font-weight: var(--typography-font-weight-bold);
	}
}
