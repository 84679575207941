@import './tokens';

/*-------------------------------------------------------*/

.lth-c-group {
	&__label {
		margin-bottom: var(--group-label-spacing);
	}

	&__items {
		display: inline-flex;
		width: fit-content;
		flex-flow: column;
		align-items: inherit;
		
		row-gap: var(--group-rowSpacing, var(--group-spacing, var(--group-spacing-dense)));
	}
	
	&.lth-is-inline &__items {
		flex-flow: row;
		flex-wrap: wrap;

		column-gap: var(--group-columnSpacing, var(--group-spacing, var(--group-spacing-normal)));
	}
}

/*-------------------------------------------------------*/
