.lth-c-select {
	/*------------------------------------------------------- */

	--select-input-bg-color: var(--color-neutral-50);
	--select-input-text-color: var(--color-neutral-800);
	--select-input-border-width: var(--border-width-s);
	--select-input-border-color: var(--color-neutral-400);
	--select-input-border-radius: var(--border-radius-s);
	--select-input-inset-hspacing: var(--space-inline-s);
	
	--select-input-inset-spacing: var(--space-stack-s) var(--space-inline-s);
	--select-input-inset-spacing--small: var(--space-stack-xs) var(--space-inline-s);
	--select-input-inset-spacing--large: var(--space-stack-m) var(--space-inline-s);

	--select-input-hover-border-color: var(--color-neutral-600);

	--select-input-placeholder-text-color: var(--color-neutral-500);
	--select-input-disabled-placeholder-text-color: var(--color-neutral-400);
	
	--select-input-arrow-color: var(--color-neutral-800);
	--select-input-disabled-arrow-color: var(--color-neutral-400);

	--select-focus-border-color: var(--control-focus-border-color);
	--select-focus-border-width: var(--control-focus-border-width);
	--select-focus-shadow: var(--control-focus-shadow);

	--select-input-error-border-color: var(--control-error-color);
	--select-input-error-hover-border-color: var(--control-error-hover-color);

	/*------------------------------------------------------- */
}
