.lth-c-toggle {
	/*------------------------------------------------------- */

	--toggle-track-unchecked-bg-color: var(--color-neutral-600);
	--toggle-track-checked-bg-color: var(--color-success-200);
	--toggle-track-disabled-bg-color: var(--color-neutral-400);
	--toggle-track-active-bg-color: var(--color-neutral-800);

	--toggle-disabled-text-color: var(--color-neutral-400);

	--toggle-focus-check-border-width: var(--border-width-s);
	--toggle-focus-check-border-radius: var(--border-radius-s);
	--toggle-focus-check-border-color: var(--color-primary-600);

	--toggle-error-text-color: var(--control-error-color);

	--toggle-track-width: 32px;
	--toggle-track-height: 16px;
	--toggle-handle-size: 12px;

	--toggle-direction-left: row;
	--toggle-direction-right: row-reverse;
	--toggle-direction-top: column;

	--toggle-justify-left: flex-start;
	--toggle-align-left: center;
	--toggle-justify-right: flex-end;
	--toggle-align-right: center;
	--toggle-justify-top: flex-start;
	--toggle-align-top: flex-start;

	--toggle-label-margin-left: 0 var(--space-inline-s) 0 0;
	--toggle-label-margin-right: 0 0 0 var(--space-inline-s);
	--toggle-label-margin-top: 0 0 var(--space-stack-xxs) 0;

	--toggle-focus: var(--control-focus);

	/*------------------------------------------------------- */
}
